import React, { useState } from 'react'
import { Grid, Button, Typography, TextField, FormControl } from '@mui/material'
import logo from '../../img/LogoEuros.png'
import { Navigate } from "react-router-dom"
import { fetchSetPass } from '../../data'
import { setPassValidation } from '../../validation'

const SetPass = ({ login }) => {
    const [pass, setPass] = useState({ login: login })
    const [passStatus, setPassStatus] = useState(false)
    const [err, setErr] = useState({ status: false, content: '' })

    const handleOnChanche = (e) => {
        setPass({
            ...pass,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = () => {
        if (pass && pass.login && pass.password && pass.pin) {
            fetchSetPass({ pass })
                .then(response => {
                    setPassStatus(true)

                }).catch(e => setErr({ status: true, content: setPassValidation(parseInt(e.message)) }))
        } else {
            setErr({ status: true, content: "Uzupełnij dane." })
        }
    }

    const handleSubmitEnter = (e) => {
        if (e.key === "Enter")
            handleSubmit()
    }
    return (
        <React.Fragment>
            {passStatus ? <Navigate to="/login" replace /> : null}
            <FormControl onKeyDown={(e) => handleSubmitEnter(e)}>
                <Grid container direction="row" >
                    <Grid item xs={4}>
                        <img alt={'Euros Energy'} src={logo} style={{ display: 'block', margin: '20px 0', maxWidth: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container direction="row" >
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ margin: "20px auto", textTransform: "uppercase", display: 'block', width: '100%' }}>Ustaw hasło</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginBottom: "30px" }} spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <TextField variant="standard" value={login}
                            type="text" name="login" label="login" required />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                        <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={pass && pass.password ? pass.password : ''}
                            type="password" name="password" label="hasło" required />
                    </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginBottom: "30px" }} spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={pass && pass.pin ? pass.pin : ''}
                            type="text" name="pin" label="Kod autoryzujący" required />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                        <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={pass && pass.confirmPassword ? pass.confirmPassword : ''}
                            type="password" name="confirmPassword" label="powtórz hasło" required />
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12} md={12}>
                        <Typography style={{ margin: "30px 0 0" }} variant="body1">* Wszystkie pola są wymagane.</Typography>
                        <Typography style={{ margin: "0", color: 'yellow' }} variant="body1"> {err && err.content ? err.content : null} </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="row" >
                    <Grid item xs={12} md={12}><br />
                        <Button onClick={() => handleSubmit()}
                            variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} type="submit">zapisz hasło</Button>

                    </Grid>
                </Grid>
            </FormControl>
        </React.Fragment>
    )
}

export default SetPass