import { createTheme } from '@mui/material/styles'
import { plPL } from '@mui/material/locale'

let theme = createTheme({
    components: {
        // Style sheet name 
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderRadius: '60px',
                    border: '2px solid #FFFFFF',
                    color: '#FFFFFF',
                    width: '220px',
                    fontSize: '18px',
                    textTransform: 'none',
                    margin: '20px auto',
                    '&:hover': {
                        border: '2px solid #FFFFFF',
                        background: '#0f4491'
                    },
                    "&.Mui-disabled": {
                        color: "#ffffff",
                        border: "2px solid #ffffff",
                        opacity: 0.4
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                color: "#ffffff",
                standard: {
                    color: "#ffffff",
                    '&::before': {
                        color: "#ffffff",
                        borderBottom: '1px solid #FFFFFF'
                    },
                },
                underline: {
                    '&:hover': {
                        '&:not(.Mui-disabled)': {
                            '&:before': {
                                borderBottom: "1px solid #FFFFFF",
                                color: "#ffffff",
                            },
                        },
                    },
                    '&::before': {
                        color: "#ffffff",
                        borderBottom: '1px solid #FFFFFF',
                    },
                    '&::after': {
                        color: "#ffffff",
                        borderBottom: '1px solid #FFFFFF'
                    },
                },
                '@media (max-width:1024px)': {
                    width: '100%',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#ffffff'
                },
                '&::before': {
                    border: '1px solid #FFFFFF'
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                    "&.Mui-focused": {
                        color: "#ffffff"
                    },
                    '@media (max-width:460px)': {
                        fontSize: '18px',
                        lineHeight: '30px',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                    margin: "0 auto",
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: "#ffffff"
                },
                track: {
                    border: "none"
                },
                rail: {
                    opacity: 1
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                    '&.Mui-checked': {
                        color: "#ffffff"
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    float: 'left',
                    paddingTop: 0,
                    paddingLeft: 0,
                    color: "#ffffff",
                    '&.Mui-checked': {
                        color: "#ffffff"
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    lineHeight: 1.2,
                    '&.Mui-checked': {
                        color: "#ffffff"
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: "rgba(0, 0, 0, 0.85)",
                    border: "2px solid rgba(255, 255, 255, 0.6)",
                    color: "#ffffff",
                    borderRadius: "12px",
                    padding: '15px 40px',
                    maxHeight: '500px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    '@media (max-width:600px)': {
                        padding: '5px',
                    },
                }
            }
        }
    },
    typography: {
        fontFamily: [
            'Geogrotesque-Regular',
            'Geogrotesque-SemiBold',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: 'Geogrotesque-SemiBold',
            fontSize: '36px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '30px',
            },
            '@media (max-width:600px)': {
                fontSize: '26px',
            },
        },
        h2: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '124px',
            // display: 'inline-block',
            margin: '0 auto',
            textAlign: 'center',
            '@media (max-width:960px)': {
                fontSize: '92px',
            },
            '@media (max-width:600px)': {
                fontSize: '68px',
            },
        },
        h3: {
            fontFamily: 'Geogrotesque-SemiBold',
            fontSize: '18px',
            margin: '20px auto 0',
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        h4: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '26px',
            margin: '20px auto 0',
            '@media (max-width:760px)': {
                fontSize: '22px',
            },
            '@media (max-width:390px)': {
                fontSize: '18px',
            },
        },
        h5: {
            fontFamily: 'Geogrotesque-Regular',
            fontSize: '22px',
            margin: '10px auto 0',
            '@media (max-width:760px)': {
                fontSize: '18px',
            },
            '@media (max-width:460px)': {
                fontSize: '16px',
            },
        },
        body2: {
            fontSize: '14px',
            margin: '0 auto',
            fontWeight: 'lighter',
            '@media (max-width:600px)': {
                fontSize: '10px',
            },
        },
        body1: {
            fontSize: '22px',
            color: '#ffffff !important',
            '@media (max-width:885px)': {
                fontSize: '20px',
            },
            '@media (max-width:685px)': {
                fontSize: '14px',
            },
            '@media (max-width:600px)': {
                fontSize: '16px',
            },
        },
    },
}, plPL)

export default theme