import React, { useState } from 'react'
import { Grid, Button, Typography, TextField, FormControl, FormLabel, Checkbox } from '@mui/material'
import logo from '../../img/LogoEuros.png'
import { Link } from "react-router-dom"
import { fetchRegister } from '../../data'
import { registerValidation } from '../../validation'
import { SetPass } from '../../components'

const Registration = () => {
    const [registration, setRegistration] = useState(null)
    const [regStatus, setRegStatus] = useState(false)
    const [consents, setConsents] = useState(0)
    const [err, setErr] = useState({ status: false, content: '' })

    const handleCheckbox = (e) => {
        if (e.target.checked === true)
            setConsents(consents + 1)
        else
            setConsents(consents - 1)
    }

    const handleOnChanche = (e) => {
        setRegistration({
            ...registration,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        if (registration && registration.first_name && registration.last_name && registration.email && registration.phone && registration.post_code && registration.city) {
            fetchRegister({ registration })
                .then(response => {
                    if (response.status === 'ok')
                        setRegStatus(true)

                }).catch(e => setErr({ status: true, content: registerValidation(parseInt(e.message)) }))
        } else {
            setErr({ status: true, content: "Uzupełnij dane." })
        }
    }

    const handleSubmitEnter = (e) => {
        if (e.key === "Enter")
            handleSubmit()
    }
    return (
        <React.Fragment>
            {regStatus ? <SetPass login={registration.phone} /> :
                <FormControl onKeyDown={(e) => handleSubmitEnter(e)}>
                    <Grid container direction="row" >
                        <Grid item xs={4}>
                            <img alt={'Euros Energy'} src={logo} style={{ display: 'block', margin: '20px 0', maxWidth: '100%' }} />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ margin: "20px auto", textTransform: "uppercase", display: 'block', width: '100%' }}>Rejestracja</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }} spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.first_name ? registration.first_name : ''}
                                type="text" name="first_name" label="imię" required />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.last_name ? registration.last_name : ''}
                                type="text" name="last_name" label="nazwisko" required />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }} spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.email ? registration.email : ''}
                                type="text" name="email" label="e-mail" required />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.phone ? registration.phone : ''}
                                type="text" name="phone" label="numer telefonu" required />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }} spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.post_code ? registration.post_code : ''}
                                type="text" name="post_code" label="kod pocztowy" required />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                            <TextField onChange={(e) => handleOnChanche(e)} variant="standard" value={registration && registration.city ? registration.city : ''}
                                type="text" name="city" label="miasto" required />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }}>
                        <Grid item xs={12} md={12}>
                            <FormLabel style={{ textAlign: 'justify' }}><Checkbox onChange={(e) => handleCheckbox(e)} id='regulations' name='regulations' required /><span style={{ color: '#ffffff', display: 'inline-block', width: '95%' }}>
                                Oświadczam, że zapoznałem/am się z <Link style={{ color: "#ffffff" }} to='https://epompa.eurosenergy.com/Regulamin.pdf'>Regulaminem</Link> korzystania z aplikacji ePompa i akceptuję jego postanowienia. Zostałem/am poinformowany/a,
                                że z uwagi na charakter funkcjonalności Aplikacji, po spełnieniu świadczenia utracę prawo do odstąpienia od umowy
                                o świadczenie usługi drogą elektroniczną. *</span></FormLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }}>
                        <Grid item xs={12} md={12}>
                            <FormLabel style={{ textAlign: 'justify' }}><Checkbox onChange={(e) => handleCheckbox(e)} name='privacy' id='privacy' required /><span style={{ color: '#ffffff', display: 'inline-block', width: '95%' }}>
                                Oświadczam, że zostałem/am poinformowany/a,
                                że administratorem moich danych osobowych jest Euros Energy sp. z o.o. z siedzibą w Koprkach. Moje dane przetwarzane są
                                w celu założenia i dostarczania usługi utrzymania konta w niniejszej aplikacji, w tym korzystania z jej funkcjonalności,
                                a także w celach marketingowych (kontakt telefoniczny przedstawiciela handlowego). Przysługuje mi prawo dostępu do danych osobowych,
                                ich sprostowania, ograniczenia przetwarzania, przenoszenia, usunięcia, sprzeciwu, a także złożenia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
                                Pozostałe informacje dotyczące przetwarzania danych osobowych znajdują się w <Link style={{ color: "#ffffff" }} to='https://epompa.eurosenergy.com/Polityka-prywatnosci.pdf'>Polityce Prywatności</Link>. *</span></FormLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ marginBottom: "30px" }}>
                        <Grid item xs={12} md={12}>
                            <FormLabel style={{ textAlign: 'justify' }}><Checkbox onChange={(e) => handleCheckbox(e)} name='marketing' id='marketing' required /><span style={{ color: '#ffffff', display: 'inline-block', width: '95%' }}>Wyrażam zgodę na nawiązanie ze mną kontaktu telefonicznego
                                poprzez wiadomość SMS dla celów marketingowych Euros Energy sp. z o.o. z siedzibą w Koprkach. W każdej chwili mogę wycofać wyrażoną zgodę, wysyłając wiadomość
                                z odpowiednim wnioskiem na adres e-mail: <a style={{ color: "#ffffff" }} href="mailto:office@eurosenergy.com">office@eurosenergy.com</a>. Wycofanie zgody nie będzie miało wpływu na zgodność z prawem przesyłania wiadomości przed jej wycofaniem,
                                jednak będzie wiązało się z rozwiązaniem umowy o świadczenie usług drogą elektroniczną, usunięciem konta w aplikacji i niemożnością korzystania
                                z jej funkcjonalności. *</span></FormLabel>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12} md={12}>
                            <Typography style={{ margin: "30px 0 0" }} variant="body1">* Wszystkie pola są wymagane.</Typography>
                            <Typography style={{ margin: "0", color: 'yellow' }} variant="body1"> {err && err.content ? err.content : null} </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item xs={12} md={12}><br />
                            <Button onClick={() => handleSubmit()} variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} disabled={consents === 3 ? false : true} type="submit">wyślij</Button>
                            <Link to='/login'><Button
                                variant="outlined" color="primary" style={{ marginTop: "20px", display: "block" }}>logowanie</Button></Link>
                        </Grid>
                    </Grid>
                </FormControl>}
        </React.Fragment>
    )

}

export default Registration