const errorMessage = (status) => {
    switch (status) {
        case 412:
            return "Podane konto nie istnieje. Sprawdź dane logowania lub przejdź do rejestracji."
        case 414:
            return "Nieprawidłowy numer telefonu."
        case 415:
            return "Nieprawidłowy adres e-mail."
        case 416:
            return "Nieprawidłowy kod autoryzacyjny."
        case 417:
            return "Taki kod autoryzacyjny nie istnieje."

        default:
            return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
    }
}

export default errorMessage