import React, { useState } from 'react'
import { Grid, Button, Typography, TextField, FormControl, Dialog, DialogContent } from '@mui/material'
import { decodeToken } from "react-jwt"
import { fetchLogin } from '../../data'
import { Navigate, Link } from "react-router-dom"
import logo from '../../img/LogoEuros.png'
import { loginValidation } from '../../validation'

const Login = ({ setBuilding, setToken }) => {
    const [login, setLogin] = useState(null)
    const [err, setErr] = useState({ status: false, content: '' })
    const handleOnChanche = (e) => {
        setLogin({
            ...login,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        if (login && login.login && login.password) {
            fetchLogin(login.login, login.password, setBuilding, setToken)
                .then(response => {
                    setLogin({
                        ...login,
                        auth: true
                    })
                }).catch(e => setErr({ status: true, content: loginValidation(parseInt(e.message)) }))
        } else {
            setErr({ status: true, content: "Uzupełnij dane logowania." })
        }
    }

    const handleSubmitEnter = (e) => {
        if (e.key === "Enter")
            handleSubmit()
    }

    const handleClose = () => {
        setErr({
            ...err,
            status: false
        })
    }

    return (
        <React.Fragment>
            {login && login.auth ? <Navigate to="/app" replace /> : null}
            <FormControl onKeyDown={(e) => handleSubmitEnter(e)}>
                <Grid container direction="row" >
                    <Grid item sm={4} xs={6}>
                        <img alt={'Euros Energy'} src={logo} style={{ display: 'block', margin: '20px 0', maxWidth: '100%' }} />
                    </Grid>
                </Grid>
                <Grid container direction="row" >
                    <Grid item xs={12}>
                        <Typography variant="h4" style={{ margin: "20px auto", textTransform: "uppercase", display: 'block', width: '100%' }}>Logowanie</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <TextField onChange={(e) => handleOnChanche(e)} style={{ marginBottom: "30px", width: "100%" }} variant="standard"
                            type="text" name="login" label="login" value={login && login.login ? login.login : ''} required />
                        <TextField onChange={(e) => handleOnChanche(e)} style={{ marginBottom: "30px", width: "100%" }} variant="standard"
                            type="password" name="password" label="hasło" value={login && login.password ? login.password : ''} required />
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12} md={12}>
                        <Typography style={{ margin: "30px 0 0" }} variant="body1">* Wszystkie pola są wymagane.</Typography>
                        <Typography style={{ margin: "0", color: 'yellow' }} variant="body1"> {err && err.content ? err.content : null} </Typography>
                    </Grid>
                </Grid>

                <Grid container direction="row" >
                    <Grid item xs={12} md={12}><br />
                        <Button onClick={() => handleSubmit()}
                            variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} type="submit">zaloguj</Button>
                        <Link to='/registration'><Button
                            variant="outlined" color="primary" style={{ marginTop: "20px", display: "block" }}>rejestracja</Button></Link>
                    </Grid>
                </Grid>
                <Grid container direction="row" >
                    <Grid item xs={12} md={12}></Grid>
                </Grid>
            </FormControl>
            {/* <Dialog onClose={handleClose} open={err.status}>
                <Typography style={{ margin: "30px 0" }} variant="body1">{err.content}</Typography>
    </Dialog> */}
        </React.Fragment>
    )
}

export default Login