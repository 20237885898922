const fetchRegister = async (payload) => {
  // console.log(JSON.stringify(JSON.stringify(payload))) 
  const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/euros_demo/register`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify(payload.registration)
  }).then(res => {
    if (res.ok) {
      return res.json()
    } else {
      throw new Error(res.status)
    }
  })

  return res
}

export default fetchRegister