import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Mousewheel } from 'swiper/core'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const slideList = (name) => {
    const tableList = []
    // console.log(step)
    if (name === "temperatura_komfortowa_zima") {
        for (let i = 15; i <= 25; i++) {
            tableList.push(<SwiperSlide key={name + i}>{i}</SwiperSlide>)
        }
    } else if (name === "temperatura_cwu") {
        for (let i = 35; i <= 55; i++) {
            tableList.push(<SwiperSlide key={name + i}>{i}</SwiperSlide>)
        }
    }
    else {
        for (let i = 2014; i <= 2021; i++) {
            tableList.push(<SwiperSlide key={name + i}>{i}</SwiperSlide>)
        }
    }

    return tableList
}

SwiperCore.use([Mousewheel])

const CarouselInput = ({ init, name, handleSettings }) => {
    const [slide, setSlide] = useState(init)
    
    const onChangeStop = (index, value, real) => {
        setSlide(parseFloat(value[index].textContent))
        if(name !== "sezon")
        handleSettings(name, parseFloat(value[index].textContent))
        else
        handleSettings(parseFloat(value[index].textContent))
    }

      

    return (
        <React.Fragment>
            <Swiper
                onSlideChange={(swiper) => onChangeStop(swiper.activeIndex, swiper.slides, swiper.realIndex)}
                initialSlide={name === 'sezon'? init - 2014 : slide} slidesPerView={3}
                centeredSlides={true} direction="vertical" loop={true}
                loopAdditionalSlides={20} mousewheel
            >
                {slideList(name)}
            </Swiper>
        </React.Fragment>
    )
}

export default CarouselInput