import React from "react"

const Budynek = ({ info }) => {
    return (
        <React.Fragment>
            {info ?
                <svg width="95px"
                    viewBox="0 0 693.83 693.83">
                    <g id="Layer_x0020_1">
                        <g id="_2986860112160">
                            <path fill="#FEFEFE" d="M549.21 205.23l0.04 0.14c-0.02,-0.07 -0.03,-0.13 -0.04,-0.14zm0.08 0.37c0,0.03 0,0.05 0,0.08 0,-0.03 0,-0.06 0,-0.08zm-202.38 -164.45c-4.24,0 -9.84,2.78 -13.32,6.61l-0.76 0.77 -169.27 147.41c-4.7,3.95 -7.14,8.7 -7.14,13.82l0 194.19c0,10.02 8.19,18.17 18.28,18.17l344.53 0c10.02,0 18.17,-8.15 18.17,-18.17l0 -193.76c-0.06,-0.19 -0.11,-0.38 -0.17,-0.57l-0.25 -0.95c-0.24,-0.79 -0.39,-1.75 -0.39,-2.7 -0.03,-0.6 -0.28,-1.75 -0.8,-3.42l-0.21 -0.94c-0.61,-1.55 -1.4,-3.05 -2.32,-4.21l-172.9 -151.43c-2.75,-3.13 -7.67,-4.82 -13.46,-4.82zm172.32 393.68l-344.53 0c-17.08,0 -30.98,-13.86 -30.98,-30.88l0 -194.19c0,-8.88 4.11,-17.21 11.59,-23.48l168.95 -147.11c5.86,-6.48 14.78,-10.71 22.65,-10.71 9.56,0 17.85,3.24 22.74,8.87l0.18 0.16 172.8 151.34c2.06,2.39 3.73,5.37 4.97,8.88l0.32 1.12c0.82,2.67 1.24,4.75 1.35,6.7l0.19 0.71c0.32,1.1 0.63,2.25 0.63,3.53l0 194.19c0,17.02 -13.86,30.88 -30.88,30.88z" />
                            <path fill="#FEFEFE" d="M172.19 521.32l0 14.66 8.09 0c4.67,0 8.67,-1.71 8.67,-6.09l0 -3.04c0,-3.62 -4,-5.53 -8.67,-5.53l-8.09 0zm0 -25.34l0 13.72 4.96 0c4.66,0 8.66,-1.81 8.66,-6.29l0 -2.48c0,-4.38 -4.01,-4.95 -8.66,-4.95l-4.96 0zm-12.95 52.39c-2.01,0 -2.76,-0.86 -2.76,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l17.9 0c17.9,0 24.2,6.67 24.2,14.85l0 5.24c0,4.57 -2.19,8.1 -6.48,10.57 5.91,2.39 9.62,6.39 9.62,11.44l0 7.04c0,8.19 -6.29,15.62 -24.2,15.62l-21.04 0z" />
                            <path fill="#FEFEFE" d="M228.76 530.08c0,4.38 3.91,6.18 8.57,6.18 4.67,0 8.67,-1.8 8.67,-6.18l0 -43.63c0,-2 0.76,-2.86 2.76,-2.86l10.28 0c2.01,0 2.66,0.86 2.66,2.86l0 46.39c0,8.19 -6.47,16.29 -24.38,16.29 -17.91,0 -24.29,-8.1 -24.29,-16.29l0 -46.39c0,-2 0.77,-2.86 2.76,-2.86l10.29 0c2,0 2.66,0.86 2.66,2.86l0 43.63z" />
                            <path fill="#FEFEFE" d="M288.38 496.46l0 39.05 8.57 0c4.67,0 8.67,-1.81 8.67,-6.19l0 -26.67c0,-4.37 -4,-6.18 -8.67,-6.18l-8.57 0zm-13.33 51.91c-1.72,0 -2.38,-0.95 -2.38,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l21.52 0c17.91,0 24.38,8.1 24.38,16.29l0 32.19c0,8.19 -6.47,16.29 -24.38,16.29l-21.9 0z" />
                            <path fill="#FEFEFE" d="M349.43 505.41c0.76,2.19 1.52,5.33 1.62,5.71 0.1,-0.38 1.04,-3.81 1.9,-5.71l8.01 -18.96c0.67,-1.62 1.33,-2.86 3.33,-2.86l10.09 0c2.01,0 2.95,1.05 2.1,2.86l-17.53 37.91 0 21.14c0,2.01 -0.66,2.86 -2.66,2.86l-10.29 0c-2,0 -2.76,-0.85 -2.76,-2.86l0 -19.24 -17.63 -39.82c-0.76,-1.71 0,-2.86 2.01,-2.86l11.62 0c2,0 2.85,1.15 3.43,2.86l6.76 18.96z" />
                            <path fill="#FEFEFE" d="M432.01 545.51c0,2.01 -0.67,2.86 -2.66,2.86l-9.34 0c-1.42,0 -2.01,-0.38 -2.57,-1.33l-15.24 -25.34c-1.62,-2.57 -3.05,-5.9 -4.1,-8.57l0 32.38c0,2.01 -0.66,2.86 -2.66,2.86l-9.34 0c-2,0 -2.75,-0.85 -2.75,-2.86l0 -59.05c0,-2 0.76,-2.86 2.75,-2.86l9.34 0c1.24,0 2,0.39 2.37,1.14l16.11 26.95c1.13,2.01 2.37,4.86 3.34,7.15l0 -32.38c0,-2 0.75,-2.86 2.75,-2.86l9.34 0c2,0 2.66,0.86 2.66,2.86l0 59.05z" />
                            <path fill="#FEFEFE" d="M446.2 548.36c-2.01,0 -2.75,-0.86 -2.75,-2.86l0 -59.05c0,-2 0.75,-2.86 2.75,-2.86l32.58 0c2,0 2.86,0.66 2.86,2.66l0 7.43c0,2.01 -0.86,2.76 -2.86,2.76l-19.63 0 0 12.39 18.1 0c2,0 2.86,0.66 2.86,2.66l0 6.95c0,2 -0.86,2.75 -2.86,2.75l-18.1 0 0 14.29 20.57 0c2.01,0 2.86,0.67 2.86,2.66l0 7.43c0,2 -0.85,2.76 -2.86,2.76l-33.52 0z" />
                            <path fill="#FEFEFE" d="M542.01 545.51c0.95,1.42 0.57,2.86 -1.43,2.86l-13.14 0c-2.01,0 -3.05,-1.43 -4,-2.86l-15.05 -23.33 -1.05 0 0 23.33c0,2.01 -0.67,2.86 -2.67,2.86l-10.28 0c-2.01,0 -2.76,-0.85 -2.76,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l10.28 0c2,0 2.67,0.86 2.67,2.86l0 24.29 0.94 0 14.58 -24.29c0.95,-1.52 2.48,-2.86 4.47,-2.86l10.95 0c2,0 2.28,1.33 1.33,2.86l-18.1 28.95 20.49 30.1z" />
                        </g>
                        <rect fill="none" width="693.83" height="693.83" />
                    </g>
                </svg>
                : <svg width="95px"
                    viewBox="0 0 693.83 693.83">
                    <g id="Layer_x0020_1">
                        <metadata id="CorelCorpID_0Corel-Layer" />
                        <g id="_2986866489472">
                            <path fill="#2B4388" d="M549.21 205.23l0.04 0.14c-0.02,-0.07 -0.03,-0.13 -0.04,-0.14zm0.08 0.37c0,0.03 0,0.05 0,0.08 0,-0.03 0,-0.06 0,-0.08zm-202.38 -164.45c-4.24,0 -9.84,2.78 -13.32,6.61l-0.76 0.77 -169.27 147.41c-4.7,3.95 -7.14,8.7 -7.14,13.82l0 194.19c0,10.02 8.19,18.17 18.28,18.17l344.53 0c10.02,0 18.17,-8.15 18.17,-18.17l0 -193.76c-0.06,-0.19 -0.11,-0.38 -0.17,-0.57l-0.25 -0.95c-0.24,-0.79 -0.39,-1.75 -0.39,-2.7 -0.03,-0.6 -0.28,-1.75 -0.8,-3.42l-0.21 -0.94c-0.61,-1.55 -1.4,-3.05 -2.32,-4.21l-172.9 -151.43c-2.75,-3.13 -7.67,-4.82 -13.46,-4.82zm172.32 393.68l-344.53 0c-17.08,0 -30.98,-13.86 -30.98,-30.88l0 -194.19c0,-8.88 4.11,-17.21 11.59,-23.48l168.95 -147.11c5.86,-6.48 14.78,-10.71 22.65,-10.71 9.56,0 17.85,3.24 22.74,8.87l0.18 0.16 172.8 151.34c2.06,2.39 3.73,5.37 4.97,8.88l0.32 1.12c0.82,2.67 1.24,4.75 1.35,6.7l0.19 0.71c0.32,1.1 0.63,2.25 0.63,3.53l0 194.19c0,17.02 -13.86,30.88 -30.88,30.88z" />
                            <path fill="#2B4388" d="M172.19 521.32l0 14.66 8.09 0c4.67,0 8.67,-1.71 8.67,-6.09l0 -3.04c0,-3.62 -4,-5.53 -8.67,-5.53l-8.09 0zm0 -25.34l0 13.72 4.96 0c4.66,0 8.66,-1.81 8.66,-6.29l0 -2.48c0,-4.38 -4.01,-4.95 -8.66,-4.95l-4.96 0zm-12.95 52.39c-2.01,0 -2.76,-0.86 -2.76,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l17.9 0c17.9,0 24.2,6.67 24.2,14.85l0 5.24c0,4.57 -2.19,8.1 -6.48,10.57 5.91,2.39 9.62,6.39 9.62,11.44l0 7.04c0,8.19 -6.29,15.62 -24.2,15.62l-21.04 0z" />
                            <path fill="#2B4388" d="M228.76 530.08c0,4.38 3.91,6.18 8.57,6.18 4.67,0 8.67,-1.8 8.67,-6.18l0 -43.63c0,-2 0.76,-2.86 2.76,-2.86l10.28 0c2.01,0 2.66,0.86 2.66,2.86l0 46.39c0,8.19 -6.47,16.29 -24.38,16.29 -17.91,0 -24.29,-8.1 -24.29,-16.29l0 -46.39c0,-2 0.77,-2.86 2.76,-2.86l10.29 0c2,0 2.66,0.86 2.66,2.86l0 43.63z" />
                            <path fill="#2B4388" d="M288.38 496.46l0 39.05 8.57 0c4.67,0 8.67,-1.81 8.67,-6.19l0 -26.67c0,-4.37 -4,-6.18 -8.67,-6.18l-8.57 0zm-13.33 51.91c-1.72,0 -2.38,-0.95 -2.38,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l21.52 0c17.91,0 24.38,8.1 24.38,16.29l0 32.19c0,8.19 -6.47,16.29 -24.38,16.29l-21.9 0z" />
                            <path fill="#2B4388" d="M349.43 505.41c0.76,2.19 1.52,5.33 1.62,5.71 0.1,-0.38 1.04,-3.81 1.9,-5.71l8.01 -18.96c0.67,-1.62 1.33,-2.86 3.33,-2.86l10.09 0c2.01,0 2.95,1.05 2.1,2.86l-17.53 37.91 0 21.14c0,2.01 -0.66,2.86 -2.66,2.86l-10.29 0c-2,0 -2.76,-0.85 -2.76,-2.86l0 -19.24 -17.63 -39.82c-0.76,-1.71 0,-2.86 2.01,-2.86l11.62 0c2,0 2.85,1.15 3.43,2.86l6.76 18.96z" />
                            <path fill="#2B4388" d="M432.01 545.51c0,2.01 -0.67,2.86 -2.66,2.86l-9.34 0c-1.42,0 -2.01,-0.38 -2.57,-1.33l-15.24 -25.34c-1.62,-2.57 -3.05,-5.9 -4.1,-8.57l0 32.38c0,2.01 -0.66,2.86 -2.66,2.86l-9.34 0c-2,0 -2.75,-0.85 -2.75,-2.86l0 -59.05c0,-2 0.76,-2.86 2.75,-2.86l9.34 0c1.24,0 2,0.39 2.37,1.14l16.11 26.95c1.13,2.01 2.37,4.86 3.34,7.15l0 -32.38c0,-2 0.75,-2.86 2.75,-2.86l9.34 0c2,0 2.66,0.86 2.66,2.86l0 59.05z" />
                            <path fill="#2B4388" d="M446.2 548.36c-2.01,0 -2.75,-0.86 -2.75,-2.86l0 -59.05c0,-2 0.75,-2.86 2.75,-2.86l32.58 0c2,0 2.86,0.66 2.86,2.66l0 7.43c0,2.01 -0.86,2.76 -2.86,2.76l-19.63 0 0 12.39 18.1 0c2,0 2.86,0.66 2.86,2.66l0 6.95c0,2 -0.86,2.75 -2.86,2.75l-18.1 0 0 14.29 20.57 0c2.01,0 2.86,0.67 2.86,2.66l0 7.43c0,2 -0.85,2.76 -2.86,2.76l-33.52 0z" />
                            <path fill="#2B4388" d="M542.01 545.51c0.95,1.42 0.57,2.86 -1.43,2.86l-13.14 0c-2.01,0 -3.05,-1.43 -4,-2.86l-15.05 -23.33 -1.05 0 0 23.33c0,2.01 -0.67,2.86 -2.67,2.86l-10.28 0c-2.01,0 -2.76,-0.85 -2.76,-2.86l0 -59.05c0,-2 0.76,-2.86 2.76,-2.86l10.28 0c2,0 2.67,0.86 2.67,2.86l0 24.29 0.94 0 14.58 -24.29c0.95,-1.52 2.48,-2.86 4.47,-2.86l10.95 0c2,0 2.28,1.33 1.33,2.86l-18.1 28.95 20.49 30.1z" />
                        </g>
                        <rect fill="none" width="693.83" height="693.83" />
                    </g>
                </svg>}
        </React.Fragment>
    )
}

export default Budynek