import React from 'react'
import { Navigate } from "react-router-dom"

const Logout = () => {
    sessionStorage.removeItem('APIToken')
    sessionStorage.removeItem('building')

    return (
        <React.Fragment>
            <Navigate to="/login" replace />
        </React.Fragment>
    )
}

export default Logout