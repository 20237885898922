import ATMO7 from '../../img/ATMO7.svg'
import ATMO11 from '../../img/ATMO11.svg'
import ATMO19 from '../../img/ATMO19.svg'
import GEO from '../../img/GEO.svg'
import ATMO7_b from '../../img/ATMO7.png'
import ATMO11_b from '../../img/ATMO11.png'
import ATMO19_b from '../../img/ATMO19.png'
import GEO_b from '../../img/GEO.png'

const dicPump = {
    ATMO7: {
        DeviceName: "ATMO 7",
        DeviceFriendlyName: "EUROS ATMO 7",
        DeviceSimName: "ATMO7",
        DeviceImage: ATMO7,
        DeviceImageB: ATMO7_b,
        Info: [
            ["Moc grzewcza przy A7/W35 ", "7,5 kW"],
            ["COP wg. EN14825 ", "4,8"],
            ["Moc chłodzenia przy A35/W7", "4,26 kW"],
            ["Ciśnienie akustyczne zew.", "37 dB"],
            ["Zakres modulacji", "3,5-7,5 kW"],
            ["Zasilanie", "230 V"],
            ["Moc grzałki", "6 kW"],
            ["Przepływ powietrza", "2500 m\u00b3/h"],
            ["Min. temp. pracy", "-30 \u00b0C"],
            ["Klasa efektywności", "A+++"],
            ["Czynnik chłodniczy", "R32"],
            ["Ilość czynnika", "0,9 kg"],
            ["Masa - jedn. zew/wew", "65 kg / 25 kg"]
        ]
    },
    ATMO11: {
        DeviceName: "ATMO 11",
        DeviceFriendlyName: "EUROS ATMO 11",
        DeviceSimName: "ATMO11",
        DeviceImage: ATMO11,
        DeviceImageB: ATMO11_b,
        Info: [
            ["Moc grzewcza przy A7/W35 ", "11,7 kW"],
            ["COP wg. EN14825 ", "5,0"],
            ["Moc chłodzenia przy A35/W7", "6,6 kW"],
            ["Ciśnienie akustyczne zew.", "38 dB"],
            ["Zakres modulacji", "3,1-11,7 kW"],
            ["Zasilanie", "230 V"],
            ["Moc grzałki", "6 kW"],
            ["Przepływ powietrza", "3150 m\u00b3/h"],
            ["Min. temp. pracy", "-30 \u00b0C"],
            ["Klasa efektywności", "A+++"],
            ["Czynnik chłodniczy", "R32"],
            ["Ilość czynnika", "1,8 kg"],
            ["Masa - jedn. zew/wew", "85 kg / 25 kg"]
        ]
    },
    ATMO19: {
        DeviceName: "ATMO 19",
        DeviceFriendlyName: "EUROS ATMO 19",
        DeviceSimName: "ATMO19",
        DeviceImage: ATMO19,
        DeviceImageB: ATMO19_b,
        Info: [
            ["Moc grzewcza przy A7/W35 ", "18,5 kW"],
            ["COP wg. EN14825 ", "5,0"],
            ["Moc chłodzenia przy A35/W7", "16 kW"],
            ["Ciśnienie akustyczne zew.", "46 dB"],
            ["Zakres modulacji", "5,8-18,5 kW"],
            ["Zasilanie", "400 V"],
            ["Moc grzałki", "6 kW"],
            ["Przepływ powietrza", "7000 m\u00b3/h"],
            ["Min. temp. pracy", "-30 \u00b0C"],
            ["Klasa efektywności", "A+++"],
            ["Czynnik chłodniczy", "R32"],
            ["Ilość czynnika", "2,6 kg"],
            ["Masa - jedn. zew/wew", "130 kg / 25 kg"]
        ]
    },
    GEO11SH: {
        DeviceName: "GEO 11 SH",
        DeviceFriendlyName: "EUROS GEO 11 SH",
        DeviceSimName: "GEO11",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "10,50 kW"],
            ["COP", "4,84"],
            ["Ciśnienie akustyczne wew.", "29 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "15 \u00b0C \u00f7 62 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "165 kg"]
        ]
    },
    GEO11AC: {
        DeviceName: "GEO 11 AC",
        DeviceFriendlyName: "EUROS GEO 11 AC",
        DeviceSimName: "GEO11",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "9,86 kW"],
            ["Moc chłodzenia", "12,05 kW"],
            ["COP", "4,61"],
            ["Ciśnienie akustyczne wew.", "29 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "21 \u00b0C \u00f7 65 \u00b0C"],
            ["Temp. odb. chłodu", "7 \u00b0C \u00f7 30 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "169 kg"]
        ]
    },
    GEO14SH: {
        DeviceName: "GEO 14 SH",
        DeviceFriendlyName: "EUROS GEO 14 SH",
        DeviceSimName: "GEO14",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "13,59 kW"],
            ["COP", "4,80"],
            ["Ciśnienie akustyczne wew.", "29 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "21 \u00b0C \u00f7 65 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "172 kg"]
        ]
    },
    GEO14AC: {
        DeviceName: "GEO 14 AC",
        DeviceFriendlyName: "EUROS GEO 14 AC",
        DeviceSimName: "GEO14",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "12,50 kW"],
            ["Moc chłodzenia", "15,25 kW"],
            ["COP", "4,61"],
            ["Ciśnienie akustyczne wew.", "29 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "21 \u00b0C \u00f7 65 \u00b0C"],
            ["Temp. odb. chłodu", "7 \u00b0C \u00f7 30 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "177 kg"]
        ]
    },
    GEO18SH: {
        DeviceName: "GEO 18 SH",
        DeviceFriendlyName: "EUROS GEO 18 SH",
        DeviceSimName: "GEO18",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "17,68 kW"],
            ["COP", "4,68"],
            ["Ciśnienie akustyczne wew.", "30 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "21 \u00b0C \u00f7 65 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "174 kg"]
        ]
    },
    GEO18AC: {
        DeviceName: "GEO 18 AC",
        DeviceFriendlyName: "EUROS GEO 18 AC",
        DeviceSimName: "GEO18",
        DeviceImage: GEO,
        DeviceImageB: GEO_b,
        Info: [
            ["Moc grzewcza", "16,70 kW"],
            ["Moc chłodzenia", "20,50 kW"],
            ["COP", "4,55"],
            ["Ciśnienie akustyczne wew.", "30 dB"],
            ["Zasilanie", "400 V"],
            ["Temp. odb. ciepła", "21 \u00b0C \u00f7 65 \u00b0C"],
            ["Temp. odb. chłodu", "7 \u00b0C \u00f7 30 \u00b0C"],
            ["Opory przepływu d. z.", "21,5 kPa"],
            ["Opory przepływu g. z.", "7,9 kPa"],
            ["Klasa energetyczna", "A+++"],
            ["Czynnik chłodniczy", "R410A"],
            ["Wymiary (cm)", "60x65x167"],
            ["Masa", "179 kg"]
        ]
    },
}

export default dicPump