import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Box, Dialog, ImageList, ImageListItem } from '@mui/material'
import { fetchPompaSymulacja, fetchPumpCalc, fetchZapotrzebowanie, fetchQuicklyContact } from '../../../../data'
import { dicClimatezone, dicMapaTemperaturProgowych, dicPump } from '../../../../data/dictionary'
import { ExpenditureChart, NotMatchedPump } from './components'
import { CarouselInput } from '../../../../components'
import edit from '../../../../img/edit.svg'
import waiting from '../../../../img/waiting.svg'

const getPumpChoice = (expenditure, setPump, setChoicePump) => {
    fetchPumpCalc({
        powierzchnia: expenditure.powierzchnia_mieszkalna,
        powierzchnia_niemieszkalna: expenditure.powierzchnia_niemieszkalna,
        powierzchnia_nieogrzewana: expenditure.powierzchnia_nieogrzewana,
        strefa_klimatyczna: `${expenditure.climatezone}`,
        rok_budowy: expenditure.rok_budowy,
        rekuperacja: `${expenditure.rekuperacja}`,
        temperatura_komfortowa_zima: expenditure.temperatura_komfortowa_zima,
        liczba_domownikow: expenditure.liczba_domownikow
    })
        .then(res => res.json())
        .then(res => {
            setPump(res.data.rekomendowane_pompy)
            setChoicePump(res.data.rekomendowane_pompy[0].replaceAll(' ', ''))
        })
}

const getOrderPump = (expenditure, sezon, setZapotrzebowanie) => {
    fetchZapotrzebowanie({
        rok_budowy: expenditure.rok_budowy,
        powierzchnia_mieszkalna: expenditure.powierzchnia_mieszkalna,
        liczba_domownikow: expenditure.liczba_domownikow,
        miasto: dicClimatezone[expenditure.climatezone],
        sezon_grzewczy: sezon,
        poczatek_zakresu: `${sezon}-01-01`,
        koniec_zakresu: `${sezon}-12-31`,
        korekta_temperatury: 0,
        zuzycie_energii_w_zadanym_okresie: -1,
        rekuperacja: expenditure.rekuperacja,
        komfortowa_temperatura_ogrzewanie: expenditure.temperatura_komfortowa_zima,
        komfortowa_temperatura_cieplej_wody_uzytkowej: expenditure.temperatura_cwu,
        mapa_temperatur_progowych_ogrzewania: dicMapaTemperaturProgowych
    })
        .then(res => res.json())
        .then(res => {
            setZapotrzebowanie(res.data)
        })
}

const getPumpSimulation = (sezon, zapotrzebowanie, expenditure, setSymulacja, choicePump) => {
    fetchPompaSymulacja({
        input_warunki_brzegowe_symulacji: {
            miasto: dicClimatezone[expenditure.climatezone],
            korekta_temperatury: 0,
            sezon_grzewczy: sezon,
            poczatek_zakresu: `${sezon}-01-01`,
            koniec_zakresu: `${sezon}-12-31`,
            zapotrzebowanie_na_energie_na_stopniogodziny: zapotrzebowanie.zapotrzebowanie_na_energie_na_stopniogodziny,
            zapotrzebowanie_niezalezne_od_temperatury: zapotrzebowanie.zapotrzebowanie_niezalezne_od_temperatury,
            parametr_CROT: 1,
            raster_czasowy_symulacji: "M",
            mapa_temperatur_progowych_ogrzewania: dicMapaTemperaturProgowych
        },
        input_dane_pompy: {
            numer_krzywej_grzewczej: expenditure.krzywa_grzewcza,
            model_pompy_ciepla: choicePump,
            zapotrzebowanie_na_energie_na_stopniogodziny: zapotrzebowanie.zapotrzebowanie_na_energie_na_stopniogodziny,
            zapotrzebowanie_niezalezne_od_temperatury: zapotrzebowanie.zapotrzebowanie_niezalezne_od_temperatury,
            komfortowa_temperatura_cieplej_wody_uzytkowej: expenditure.temperatura_cwu,
            mapa_temperatur_progowych_ogrzewania: dicMapaTemperaturProgowych
        }
    })
        .then(res => res.json())
        .then(res => setSymulacja(res.data.wynik_symulacji))

}

const Expenditure = ({ expenditure }) => {

    const [choicePump, setChoicePump] = useState(null)
    const [pump, setPump] = useState(null)
    const [zapotrzebowanie, setZapotrzebowanie] = useState(null)
    const [symulacja, setSymulacja] = useState(null)
    const [sezon, setSezon] = useState(2020)
    const [open, setOpen] = useState(false)
    const [contact, setContact] = useState(false)

    let dictionary = dicPump

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleContact = () => {
        fetchQuicklyContact().then(res => setContact(true))
    }

    useEffect(() => {
        let mounted = true

        getPumpChoice(expenditure, setPump, setChoicePump)
        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expenditure])

    useEffect(() => {
        let mounted = true
        getOrderPump(expenditure, sezon, setZapotrzebowanie)
        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sezon, expenditure])

    useEffect(() => {
        let mounted = true
        if (choicePump && zapotrzebowanie)
            getPumpSimulation(sezon, zapotrzebowanie, expenditure, setSymulacja, dictionary[choicePump.replaceAll(' ', '')].DeviceSimName)
        return () => {
            mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choicePump, expenditure, zapotrzebowanie])

    const handleChoicePump = (name) => {
        setChoicePump(name)
    }

    return (
        <React.Fragment>
            {!pump ? <img alt={'Proszę czekać, trwa przeliczanie danych...'} src={waiting} style={{ display: 'block', margin: '60px auto' }} /> : pump.length === 0 ? <NotMatchedPump /> : <React.Fragment>
                <Grid container>
                    <Grid item xs={12}><Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Sprawdź zużycie energii dla wybranej pompy ciepła</Typography></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Box style={{ width: '100%' }}>
                            {pump && choicePump ? <ImageList cols={pump ? pump.length : 1} gap={20} xs={{ height: 200 }} sm={{ height: 350 }} >
                                {pump.map(item => {
                                    return (
                                        <ImageListItem
                                            onClick={() => handleChoicePump(item.replaceAll(' ', ''))}
                                            className={`buttonImg ${item.replaceAll(' ', '') === choicePump ? 'active' : null}`}
                                            key={item.replaceAll(' ', '')}>
                                            <img alt={item} src={dicPump[item.replaceAll(' ', '')].DeviceImage} className='choicePumpImg' />
                                            <Typography variant="body" className='choicePumpTxt'>{dicPump[item.replaceAll(' ', '')].DeviceName}</Typography>
                                            <span className='line'></span>
                                            </ImageListItem>
                                    )
                                })}
                            </ImageList> : null}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}><Typography variant="h4" style={{ margin: "60px auto 20px", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Wybierz statystyki dla roku</Typography></Grid>
                    <Grid item xs={12}>
                        <Grid container className='seasonBlock'>
                            <Grid item xs={0} ></Grid>
                            <Grid item xs={9} ><Typography variant="h2">{sezon}</Typography></Grid>
                            <Grid item xs={3} ><img onClick={() => handleClickOpen()} alt="edycja sezon grzewczy" className="mode-icon" src={edit} style={{ float: "left", cursor: "pointer" }} /></Grid>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className='expenditureBlock'>
                    <Grid item xs={12}>
                        {symulacja && choicePump ? <ExpenditureChart eprice={expenditure.cena_energii} symulacja={symulacja} /> : null}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Button disabled={contact ? true : false} variant="outlined" color="primary" style={{ opacity: 1, margin: "80px auto", display: "block", background: contact ? '#009245' : 'transparent' }} onClick={() => handleContact()}>{contact ? 'Dziękujemy' : 'Poproś o ofertę'}</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                        {choicePump ? <img alt={dicPump[choicePump].DeviceFriendlyName} className='expenditurePumpImg' src={dicPump[choicePump].DeviceImageB} /> : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                {choicePump ? <Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>{dicPump[choicePump].DeviceFriendlyName}</Typography> : null}
                            </Grid>
                        </Grid>
                        <Grid container>
                            {choicePump ? dicPump[choicePump].Info.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Grid item xs={8}><Typography variant="body1">{item[0]}</Typography></Grid>
                                        <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{item[1]}</Typography></Grid>
                                    </React.Fragment>
                                )
                            }) : null}
                        </Grid>
                        <Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog onClose={handleClose} open={open}>

                    <Typography style={{ marginTop: "30px" }} variant="body1">Oczekiwana temperatura w domu</Typography>
                    <CarouselInput name={"sezon"} init={sezon} handleSettings={setSezon} />
                    <Button variant="outlined" color="primary" style={{ marginTop: "30px", display: "block" }} onClick={() => handleClose()}>Zapisz</Button>
                </Dialog>
            </React.Fragment>}
        </React.Fragment>
    )
}

export default Expenditure