import React, { useState } from 'react'
import { Grid, Typography, Button, TextField, Slider, Radio, RadioGroup, FormControlLabel, FormControl, InputAdornment } from '@mui/material'

const Building = ({ handleChange, building, handleSettings }) => {
    const handleSliderChange = (event, val) => {
        handleSettings(event.target.name, val)
    }
    const handleRadioChange = (event) => {
        handleSettings(event.target.name, parseInt(event.target.value))
    }
    const handlePriceChange = (event) => {
        if(parseFloat(event.target.value))
        handleSettings(event.target.name, parseFloat(event.target.value))
    }
    const handleYearChange = (event) => {
        if(parseInt(event.target.value))
        handleSettings(event.target.name, parseInt(event.target.value))
    }


    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}><Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Budynek</Typography></Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "0 0 0 15px" }} className='buildingBlock'>
                    <Typography style={{ marginTop: "30px" }} variant="body1">Rekuperacja</Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={building.rekuperacja}
                            name="rekuperacja"
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label="Brak centrali wentylacyjnej" />
                            <FormControlLabel value={2} control={<Radio />} label="Prosta centrala wentylacyjna" />
                            <FormControlLabel value={3} control={<Radio />} label="Zaawansowana centrala wentylacyjna" />
                            <FormControlLabel value={4} control={<Radio />} label="Centrala wentylacyjna z czujnikiem jakości powietrza" />
                        </RadioGroup>
                    </FormControl>
                    <Typography style={{ marginTop: "30px" }} variant="body1">Dystrybucja ciepła</Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={building.krzywa_grzewcza}
                            name="krzywa_grzewcza"
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value={3} control={<Radio />} label="Ogrzewanie podłogowe" />
                            <FormControlLabel value={5} control={<Radio />} label="Kaloryfery niskotemperaturowe" />
                            <FormControlLabel value={8} control={<Radio />} label="Kaloryfery wysokotemperaturowe" />
                        </RadioGroup>
                    </FormControl>
                    <Typography style={{ marginTop: "30px", float: 'left' }} variant="body1">Cena energii za 1kWh </Typography>
                    <TextField id="cena_energii" onChange={handlePriceChange} step={0.01} type='number' InputProps={{endAdornment: <InputAdornment style={{ color: "#ffffff"}} position="end">zł</InputAdornment>}}
                    inputProps={{ inputMode: 'numeric', pattern: '[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)' }} 
                    value={building.cena_energii? building.cena_energii : 0.00} name={"cena_energii"} variant="standard"
                         className='energyPrice' />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "0 15px 0 0" }} className='buildingBlock'>
                    <Typography style={{ marginTop: "30px", float: 'left' }} variant="body1">Rok budowy lub termomodernizacji</Typography>
                    <TextField id="rok_budowy" onChange={handleYearChange} value={building.rok_budowy} name={"rok_budowy"} variant="standard" type='number' 
                        style={{ marginTop: "30px", float: 'right', maxWidth: '100px' }} />
                    <Grid container>
                        <Grid item xs={10}><Typography style={{ marginTop: "30px" }} variant="body1">Ogrzewana powierzchnia mieszkalna</Typography></Grid>
                        <Grid item xs={2}><Typography style={{ marginTop: "30px", textAlign: 'right' }} variant="body1">{building.powierzchnia_mieszkalna} m<sup>2</sup></Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Slider value={building.powierzchnia_mieszkalna} onChange={handleSliderChange} name="powierzchnia_mieszkalna" step={5} min={10} max={400} aria-label="Default" valueLabelDisplay="off" />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}><Typography style={{ marginTop: "30px" }} variant="body1">Ogrzewana powierzchnia niemieszkalna <br />garaż, pomieszczenia gospodarcze</Typography></Grid>
                        <Grid item xs={2}><Typography style={{ marginTop: "30px", textAlign: 'right' }} variant="body1">{building.powierzchnia_niemieszkalna} m<sup>2</sup></Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Slider value={building.powierzchnia_niemieszkalna} onChange={handleSliderChange} name="powierzchnia_niemieszkalna" step={5} min={0} max={200} aria-label="Default" valueLabelDisplay="off" />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}><Typography style={{ marginTop: "30px" }} variant="body1">Powierzchnia nieogrzewana w budynku</Typography></Grid>
                        <Grid item xs={2}><Typography style={{ marginTop: "30px", textAlign: 'right' }} variant="body1">{building.powierzchnia_nieogrzewana} m<sup>2</sup></Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Slider value={building.powierzchnia_nieogrzewana} onChange={handleSliderChange} name="powierzchnia_nieogrzewana" step={5} min={0} max={200} aria-label="Default" valueLabelDisplay="off" />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}><Typography style={{ marginTop: "30px" }} variant="body1">Powierzchnia całkowita</Typography></Grid>
                        <Grid item xs={2}><Typography style={{ marginTop: "30px", textAlign: 'right' }} variant="body1">{building.powierzchnia_mieszkalna + building.powierzchnia_niemieszkalna + building.powierzchnia_nieogrzewana} m<sup>2</sup></Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={10}><Typography style={{ marginTop: "30px" }} variant="body1">Liczba domowników</Typography></Grid>
                        <Grid item xs={2}><Typography style={{ marginTop: "30px", textAlign: 'right' }} variant="body1">{building.liczba_domownikow}</Typography></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Slider name="liczba_domownikow" onChange={handleSliderChange} value={building.liczba_domownikow} step={1} min={0} max={10} aria-label="Default" valueLabelDisplay="off" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                    <Button variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} onClick={(e) => handleChange(e, 2)}>Dalej</Button></Grid>

            </Grid>
        </React.Fragment>
    )
}

export default Building