import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { Strefy } from '../../../../img'

const ClimateZone = ({ zone, handleSettings, handleChange }) => {

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}><Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Strefa klimatyczna</Typography></Grid>
            </Grid>
            <Grid container>
                <Grid item md={6} xs={12}>
                    <Strefy zone={zone} handleSettings={handleSettings} />
                </Grid>
                <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="body1" style={{ margin: "20px auto", textAlign: "center", display: 'block', width: '100%' }}>Wybierz strefę klimatyczną, w której mieszkasz</Typography>
                    <Typography onClick={() => handleSettings("climatezone", 1)} variant="h5" style={{ color: `${zone === 1 ? '#FFFFFF' : '#0F4491'}`, cursor: 'pointer', textAlign: "center", display: 'block', width: '100%', textTransform: "uppercase", fontWeight: 'bold', lineHeight: '45px' }}>
                        Strefa 1
                    </Typography>
                    <Typography onClick={() => handleSettings("climatezone", 2)} variant="h5" style={{ color: `${zone === 2 ? '#FFFFFF' : '#0F4491'}`, cursor: 'pointer', textAlign: "center", display: 'block', width: '100%', textTransform: "uppercase", fontWeight: 'bold', lineHeight: '45px' }}>
                        Strefa 2
                    </Typography>
                    <Typography onClick={() => handleSettings("climatezone", 3)} variant="h5" style={{ color: `${zone === 3 ? '#FFFFFF' : '#0F4491'}`, cursor: 'pointer', textAlign: "center", display: 'block', width: '100%', textTransform: "uppercase", fontWeight: 'bold', lineHeight: '45px' }}>
                        Strefa 3
                    </Typography>
                    <Typography onClick={() => handleSettings("climatezone", 4)} variant="h5" style={{ color: `${zone === 4 ? '#FFFFFF' : '#0F4491'}`, cursor: 'pointer', textAlign: "center", display: 'block', width: '100%', textTransform: "uppercase", fontWeight: 'bold', lineHeight: '45px' }}>
                        Strefa 4
                    </Typography>
                    <Typography onClick={() => handleSettings("climatezone", 5)} variant="h5" style={{ color: `${zone === 5 ? '#FFFFFF' : '#0F4491'}`, cursor: 'pointer', textAlign: "center", display: 'block', width: '100%', textTransform: "uppercase", fontWeight: 'bold', lineHeight: '45px' }}>
                        Strefa 5
                    </Typography>
                    <Button variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} onClick={(e) => handleChange(e, 1)}>Dalej</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ClimateZone