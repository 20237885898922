import React, { useState } from 'react'
import { Grid, Typography, Button, Dialog, DialogContent } from '@mui/material'
import setpoint_CWU_czuwanie from '../../../../img/setpoint-CWU-czuwanie.svg'
import setpoint_czuwanie from '../../../../img/setpoint-czuwanie.svg'
import edit from '../../../../img/edit.svg'
import { CarouselInput } from '../../../../components'

const waterTable = {
    35: 0,
    36: 1,
    37: 2,
    38: 3,
    39: 4,
    40: 5,
    41: 6,
    42: 7,
    43: 8,
    44: 9,
    45: 10,
    46: 11,
    47: 12,
    48: 13,
    49: 14,
    50: 15,
    51: 16,
    52: 17,
    53: 18,
    54: 19,
    55: 20
}
const homeTable = {
    15: 0,
    16: 1,
    17: 2,
    18: 3,
    19: 4,
    20: 5,
    21: 6,
    22: 7,
    23: 8,
    24: 9,
    25: 10
}

const Comfort = ({ handleChange, comfort, handleSettings }) => {
    const [open, setOpen] = useState({ status: false, variant: '', init: null })

    const handleClose = () => {
        setOpen({
            ...open,
            status: false
        })
    }

    const handleClickOpen = (name, init) => {
        setOpen({
            ...open,
            status: true,
            variant: name,
            init: name === 'temperatura_cwu' ? waterTable[init] : homeTable[init]
        });
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12}><Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Komfort</Typography></Grid>
            </Grid>
            <Grid container>
                <Grid item xs={0} sm={2} lg={3}></Grid>
                <Grid item xs={12} sm={8} lg={6}>
                    <Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Oczekiwana&nbsp;temperatura w&nbsp;domu</Typography>
                    <Grid container className='smallComfortBlock'>
                        <Grid item xs={0} sm={1}></Grid>
                        <Grid item xs={3} sm={2} style={{ position: 'relative', textAlign: 'center' }}>
                            <img alt="temperatura w domu" className="mode-icon" src={setpoint_czuwanie} />
                        </Grid>
                        <Grid item xs={6}><Typography variant="h2">{comfort.temperatura_komfortowa_zima}<sup>&deg;C</sup></Typography></Grid>
                        <Grid item xs={3}><img onClick={() => handleClickOpen('temperatura_komfortowa_zima', comfort.temperatura_komfortowa_zima)} alt="edycja temperatury w domu" className="mode-icon" src={edit} style={{ float: "left", cursor: "pointer" }} /></Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={0} sm={2} lg={3}></Grid>
                <Grid item xs={12} sm={8} lg={6}>
                    <Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Oczekiwana&nbsp;temperatura ciepłej&nbsp;wody</Typography>
                    <Grid container className='smallComfortBlock'>
                        <Grid item xs={0} sm={1}></Grid>
                        <Grid item xs={3} sm={2} style={{ position: 'relative', textAlign: 'center' }}>
                            <img alt="temperatura wody" className="mode-icon" src={setpoint_CWU_czuwanie} />
                        </Grid>
                        <Grid item xs={6}><Typography variant="h2">{comfort.temperatura_cwu}<sup>&deg;C</sup></Typography></Grid>
                        <Grid item xs={3}><img onClick={() => handleClickOpen('temperatura_cwu', comfort.temperatura_cwu)} alt="edycja temperatury w domu" className="mode-icon" src={edit} style={{ float: "left", cursor: "pointer" }} /></Grid>
                    </Grid>
                    <Button variant="outlined" color="primary" style={{ marginTop: "60px", display: "block" }} onClick={(e) => handleChange(e, 3)}>Dalej</Button>
                </Grid>
            </Grid>
            <Dialog onClose={handleClose} open={open.status}>

                <Typography style={{ marginTop: "30px" }} variant="body1">Oczekiwana temperatura w domu</Typography>
                <CarouselInput name={open.variant} init={open.init} handleSettings={handleSettings} />
                <Button variant="outlined" color="primary" style={{ marginTop: "30px", display: "block" }} onClick={() => handleClose()}>Zapisz</Button>
            </Dialog>
        </React.Fragment>
    )
}

export default Comfort