import React from 'react'
import { Grid, Typography } from '@mui/material'

const NotMatchedPump = () => {
    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="body1" style={{ color: 'yellow', margin: '40px auto' }}>Nie udało się dobrać pompy ciepła.</Typography>
                    <Typography variant="body1" style={{ margin: '40px auto' }}>Sprawdź parametry budynku lub skontaktuj się z działem handlowym w celu indywidualnego doboru.</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <div className='contactButton'><Typography variant="body1">+48 22 250 16 06</Typography></div>
                    <div className='contactButton'><Typography variant="body1"><a href='mailto:handlowy@eurosenergy.com'>handlowy@eurosenergy.com</a></Typography></div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NotMatchedPump