import React from "react"

const Komfort = ({ info }) => {
    return (
        <React.Fragment>
            {info ?
                <svg width="95px"
                    viewBox="0 0 379.86 379.86">
                    <g id="Layer_x0020_1">
                        <g id="_2986858195664">
                            <path fill="#FEFEFE" d="M179.01 30.44c0,-5.57 4.33,-10.1 9.57,-10.1 5.31,0 9.64,4.53 9.64,10.1l0 108.68 -19.21 0 0 -108.68zm25.96 136.8l0 -136.8c0,-9.51 -7.34,-17.24 -16.39,-17.24 -9.04,0 -16.39,7.73 -16.39,17.24l0 136.8c-11.86,6.29 -19.99,19.2 -19.99,34.08 0,21.11 16.32,38.22 36.38,38.22 20.12,0 36.38,-17.11 36.38,-38.22 0,-14.95 -8.13,-27.86 -19.99,-34.08z" />
                            <path fill="#FEFEFE" d="M110.67 298.66c0.52,0.78 0.31,1.56 -0.78,1.56l-7.19 0c-1.1,0 -1.67,-0.79 -2.19,-1.56l-8.24 -12.77 -0.57 0 0 12.77c0,1.1 -0.37,1.56 -1.46,1.56l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l5.63 0c1.09,0 1.46,0.47 1.46,1.56l0 13.3 0.52 0 7.98 -13.3c0.52,-0.83 1.36,-1.56 2.45,-1.56l6 0c1.09,0 1.25,0.73 0.73,1.56l-9.91 15.85 11.22 16.48z" />
                            <path fill="#FEFEFE" d="M131.01 274.77c0,-2.4 -2.19,-3.39 -4.74,-3.39 -2.56,0 -4.7,0.99 -4.7,3.39l0 15.44c0,2.39 2.14,3.39 4.7,3.39 2.55,0 4.74,-0.99 4.74,-3.39l0 -15.44zm-18.04 -1.51c0,-4.48 3.49,-8.92 13.3,-8.92 9.8,0 13.35,4.43 13.35,8.92l0 18.46c0,4.49 -3.55,8.92 -13.35,8.92 -9.8,0 -13.3,-4.44 -13.3,-8.92l0 -18.46z" />
                            <path fill="#FEFEFE" d="M178.51 298.66c0,1.1 -0.36,1.56 -1.46,1.56l-5.38 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -16.53c-0.06,0.16 -1.05,3.81 -1.67,5.48l-2.3 5.68c-0.41,1.04 -0.99,1.56 -2.03,1.56l-4.53 0c-1.04,0 -1.57,-0.63 -1.98,-1.56l-2.87 -6.62c-0.58,-1.35 -1.26,-3.96 -1.36,-4.38l0 16.37c0,1.1 -0.36,1.56 -1.46,1.56l-5.11 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l5.11 0c0.78,0 1.1,0.31 1.36,0.83l7.09 15.96c0.73,1.77 1.41,3.91 1.51,4.11 0,-0.1 0.52,-1.72 1.51,-4.01l6.83 -16.16c0.21,-0.52 0.63,-0.73 1.41,-0.73l5.38 0c1.09,0 1.46,0.47 1.46,1.56l0 32.33z" />
                            <path fill="#FEFEFE" d="M206.88 266.22l0 4.07c0,1.09 -0.47,1.51 -1.56,1.51l-11.94 0 0 6.78 11.1 0c1.09,0 1.56,0.36 1.56,1.46l0 4.06c0,1.09 -0.47,1.51 -1.56,1.51l-11.1 0 0 13.03c0,1.1 -0.37,1.57 -1.46,1.57l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.57l0 -32.33c0,-1.09 0.41,-1.56 1.51,-1.56l19.03 0c1.09,0 1.56,0.36 1.56,1.46z" />
                            <path fill="#FEFEFE" d="M228.83 274.77c0,-2.4 -2.19,-3.39 -4.74,-3.39 -2.56,0 -4.7,0.99 -4.7,3.39l0 15.44c0,2.39 2.14,3.39 4.7,3.39 2.55,0 4.74,-0.99 4.74,-3.39l0 -15.44zm-18.04 -1.51c0,-4.48 3.49,-8.92 13.3,-8.92 9.8,0 13.35,4.43 13.35,8.92l0 18.46c0,4.49 -3.55,8.92 -13.35,8.92 -9.8,0 -13.3,-4.44 -13.3,-8.92l0 -18.46z" />
                            <path fill="#FEFEFE" d="M251.78 271.8l0 8.29 4.17 0c2.56,0 4.74,-0.99 4.74,-3.39l0 -1.52c0,-2.39 -2.19,-3.39 -4.74,-3.39l-4.17 0zm11.99 14.03l5.11 12.83c0.36,0.94 -0.31,1.56 -1.46,1.56l-5.63 0c-0.99,0 -1.2,-0.83 -1.51,-1.56l-4.58 -11.52 -3.91 0 0 11.52c0,1.09 -0.36,1.56 -1.46,1.56l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l11.26 0c9.8,0 13.25,4.43 13.25,8.92l0 4.53c0,2.98 -1.51,5.95 -5.42,7.62z" />
                            <path fill="#FEFEFE" d="M295.21 264.76c1.1,0 1.56,0.36 1.56,1.46l0 4.07c0,1.1 -0.47,1.51 -1.56,1.51l-6.68 0 0 26.86c0,1.1 -0.36,1.56 -1.46,1.56l-5.63 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -26.86 -6.68 0c-1.1,0 -1.56,-0.41 -1.56,-1.51l0 -4.07c0,-1.09 0.47,-1.46 1.56,-1.46l21.95 0z" />
                        </g>
                        <rect fill="none" width="379.86" height="379.86" />
                    </g>
                </svg>
                : <svg width="95px"
                    viewBox="0 0 379.86 379.86">
                    <g id="Layer_x0020_1">
                        <g id="_2986714451168">
                            <path fill="#2B4388" d="M179.01 30.44c0,-5.57 4.33,-10.1 9.57,-10.1 5.31,0 9.64,4.53 9.64,10.1l0 108.68 -19.21 0 0 -108.68zm25.96 136.8l0 -136.8c0,-9.51 -7.34,-17.24 -16.39,-17.24 -9.04,0 -16.39,7.73 -16.39,17.24l0 136.8c-11.86,6.29 -19.99,19.2 -19.99,34.08 0,21.11 16.32,38.22 36.38,38.22 20.12,0 36.38,-17.11 36.38,-38.22 0,-14.95 -8.13,-27.86 -19.99,-34.08z" />
                            <path fill="#2B4388" d="M110.67 298.66c0.52,0.78 0.31,1.56 -0.78,1.56l-7.19 0c-1.1,0 -1.67,-0.79 -2.19,-1.56l-8.24 -12.77 -0.57 0 0 12.77c0,1.1 -0.37,1.56 -1.46,1.56l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l5.63 0c1.09,0 1.46,0.47 1.46,1.56l0 13.3 0.52 0 7.98 -13.3c0.52,-0.83 1.36,-1.56 2.45,-1.56l6 0c1.09,0 1.25,0.73 0.73,1.56l-9.91 15.85 11.22 16.48z" />
                            <path fill="#2B4388" d="M131.01 274.77c0,-2.4 -2.19,-3.39 -4.74,-3.39 -2.56,0 -4.7,0.99 -4.7,3.39l0 15.44c0,2.39 2.14,3.39 4.7,3.39 2.55,0 4.74,-0.99 4.74,-3.39l0 -15.44zm-18.04 -1.51c0,-4.48 3.49,-8.92 13.3,-8.92 9.8,0 13.35,4.43 13.35,8.92l0 18.46c0,4.49 -3.55,8.92 -13.35,8.92 -9.8,0 -13.3,-4.44 -13.3,-8.92l0 -18.46z" />
                            <path fill="#2B4388" d="M178.51 298.66c0,1.1 -0.36,1.56 -1.46,1.56l-5.38 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -16.53c-0.06,0.16 -1.05,3.81 -1.67,5.48l-2.3 5.68c-0.41,1.04 -0.99,1.56 -2.03,1.56l-4.53 0c-1.04,0 -1.57,-0.63 -1.98,-1.56l-2.87 -6.62c-0.58,-1.35 -1.26,-3.96 -1.36,-4.38l0 16.37c0,1.1 -0.36,1.56 -1.46,1.56l-5.11 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l5.11 0c0.78,0 1.1,0.31 1.36,0.83l7.09 15.96c0.73,1.77 1.41,3.91 1.51,4.11 0,-0.1 0.52,-1.72 1.51,-4.01l6.83 -16.16c0.21,-0.52 0.63,-0.73 1.41,-0.73l5.38 0c1.09,0 1.46,0.47 1.46,1.56l0 32.33z" />
                            <path fill="#2B4388" d="M206.88 266.22l0 4.07c0,1.09 -0.47,1.51 -1.56,1.51l-11.94 0 0 6.78 11.1 0c1.09,0 1.56,0.36 1.56,1.46l0 4.06c0,1.09 -0.47,1.51 -1.56,1.51l-11.1 0 0 13.03c0,1.1 -0.37,1.57 -1.46,1.57l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.57l0 -32.33c0,-1.09 0.41,-1.56 1.51,-1.56l19.03 0c1.09,0 1.56,0.36 1.56,1.46z" />
                            <path fill="#2B4388" d="M228.83 274.77c0,-2.4 -2.19,-3.39 -4.74,-3.39 -2.56,0 -4.7,0.99 -4.7,3.39l0 15.44c0,2.39 2.14,3.39 4.7,3.39 2.55,0 4.74,-0.99 4.74,-3.39l0 -15.44zm-18.04 -1.51c0,-4.48 3.49,-8.92 13.3,-8.92 9.8,0 13.35,4.43 13.35,8.92l0 18.46c0,4.49 -3.55,8.92 -13.35,8.92 -9.8,0 -13.3,-4.44 -13.3,-8.92l0 -18.46z" />
                            <path fill="#2B4388" d="M251.78 271.8l0 8.29 4.17 0c2.56,0 4.74,-0.99 4.74,-3.39l0 -1.52c0,-2.39 -2.19,-3.39 -4.74,-3.39l-4.17 0zm11.99 14.03l5.11 12.83c0.36,0.94 -0.31,1.56 -1.46,1.56l-5.63 0c-0.99,0 -1.2,-0.83 -1.51,-1.56l-4.58 -11.52 -3.91 0 0 11.52c0,1.09 -0.36,1.56 -1.46,1.56l-5.63 0c-1.1,0 -1.51,-0.47 -1.51,-1.56l0 -32.33c0,-1.09 0.42,-1.56 1.51,-1.56l11.26 0c9.8,0 13.25,4.43 13.25,8.92l0 4.53c0,2.98 -1.51,5.95 -5.42,7.62z" />
                            <path fill="#2B4388" d="M295.21 264.76c1.1,0 1.56,0.36 1.56,1.46l0 4.07c0,1.1 -0.47,1.51 -1.56,1.51l-6.68 0 0 26.86c0,1.1 -0.36,1.56 -1.46,1.56l-5.63 0c-1.09,0 -1.51,-0.47 -1.51,-1.56l0 -26.86 -6.68 0c-1.1,0 -1.56,-0.41 -1.56,-1.51l0 -4.07c0,-1.09 0.47,-1.46 1.56,-1.46l21.95 0z" />
                        </g>
                        <rect fill="none" width="379.86" height="379.86" />
                    </g>
                </svg>}
        </React.Fragment>
    )
}

export default Komfort