const fetchSetPass = async (payload) => {
    // console.log(JSON.stringify(JSON.stringify(payload))) 
        const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/euros_demo/set_password`, {
          method: "POST",
          headers:{
            'Content-Type': 'application/json',
            'accept': 'application/json'
          },
          body: JSON.stringify(payload.pass)
        }).then(response => {
          if (response.ok) {
            return response.json()
        } else {
            throw new Error(response.status)
        }
        })
        
        return res
}

export default fetchSetPass