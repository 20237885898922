import React from "react"

const Strefa = ({ info }) => {
    return (
        <React.Fragment>
        { info ?
        <svg width="95px"
            viewBox="0 0 5093.01 5093.01">
            <g id="Layer_x0020_1">
                <rect fill="none" width="5093.01" height="5093.01" />
                <g id="_2986702883872">
                    <path fill="#FEFEFE" d="M1669.11 3645.96c0,-32.16 -22.4,-44.03 -54.56,-44.03 -32.16,0 -55.25,11.87 -55.25,44.03l0 6.99c0,18.93 14.74,27.29 22.4,30.07l117.47 39.81c51.71,17.44 87.4,53.87 87.4,105.6l0 49.58c0,60.21 -42.68,119.57 -174.05,119.57 -131.51,0 -175.54,-59.36 -175.54,-119.57l0 -46.74c0,-14.73 5.64,-21.03 20.3,-21.03l72.68 0c14.71,0 19.6,6.3 19.6,21.03l0 31.36c0,32.21 27.27,44.11 61.55,44.11 34.2,0 62.22,-11.89 62.22,-44.11l0 -13.93c0,-25.16 -33.58,-37.05 -60.12,-46.88l-100.7 -37.74c-35.63,-13.29 -64.99,-52.37 -64.99,-92.96l0 -40.57c0,-60.14 36.29,-119.57 165.69,-119.57 129.27,0 168.5,59.44 168.5,119.57l0 25.91c0,14.66 -5.64,20.91 -20.3,20.91l-72.75 0c-14.66,0 -19.53,-6.25 -19.53,-20.91l0 -10.52z" />
                    <path fill="#FEFEFE" d="M2128.39 3516.66c14.74,0 20.97,4.88 20.97,19.53l0 54.56c0,14.66 -6.23,20.22 -20.97,20.22l-89.5 0 0 360.06c0,14.71 -4.89,20.96 -19.54,20.96l-75.52 0c-14.66,0 -20.22,-6.25 -20.22,-20.96l0 -360.06 -89.52 0c-14.71,0 -20.96,-5.56 -20.96,-20.22l0 -54.56c0,-14.66 6.25,-19.53 20.96,-19.53l294.3 0z" />
                    <path fill="#FEFEFE" d="M2312.29 3611.01l0 111.22 55.91 0c34.29,0 63.6,-13.29 63.6,-45.48l0 -20.27c0,-32.19 -29.31,-45.47 -63.6,-45.47l-55.91 0zm160.82 188.1l68.48 171.94c4.89,12.62 -4.15,20.97 -19.54,20.97l-75.53 0c-13.29,0 -16.08,-11.19 -20.3,-20.97l-61.49 -154.5 -52.45 0 0 154.5c0,14.66 -4.89,20.97 -19.54,20.97l-75.52 0c-14.73,0 -20.3,-6.32 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l150.97 0c131.44,0 177.58,59.38 177.58,119.51l0 60.8c0,39.9 -20.27,79.73 -72.66,102.13z" />
                    <path fill="#FEFEFE" d="M2639.45 3992.02c-14.71,0 -20.21,-6.32 -20.21,-20.97l0 -433.47c0,-14.66 5.5,-20.91 20.21,-20.91l239.14 0c14.66,0 20.96,4.83 20.96,19.54l0 54.57c0,14.66 -6.3,20.22 -20.96,20.22l-144.06 0 0 90.92 132.85 0c14.67,0 20.97,4.88 20.97,19.54l0 51.03c0,14.73 -6.3,20.21 -20.97,20.21l-132.85 0 0 104.92 150.98 0c14.71,0 20.96,4.88 20.96,19.54l0 54.56c0,14.67 -6.25,20.28 -20.96,20.28l-246.07 0z" />
                    <path fill="#FEFEFE" d="M3269.41 3536.22l0 54.55c0,14.66 -6.3,20.24 -20.96,20.24l-160.09 0 0 90.92 148.88 0c14.67,0 20.97,4.88 20.97,19.54l0 54.55c0,14.66 -6.3,20.3 -20.97,20.3l-148.88 0 0 174.73c0,14.66 -4.95,20.97 -19.61,20.97l-75.52 0c-14.67,0 -20.22,-6.32 -20.22,-20.97l0 -433.47c0,-14.66 5.55,-20.91 20.22,-20.91l255.21 0c14.66,0 20.96,4.81 20.96,19.54z" />
                    <path fill="#FEFEFE" d="M3470.03 3775.27c-11.19,-42.6 -20.97,-139.85 -21.65,-146.09 -1.41,9.83 -12.62,105.58 -23.81,146.09l-10.44 37.8 65.66 0 -9.77 -37.8zm44.05 -237.7l126.48 433.48c4.27,13.29 -0.69,20.97 -15.36,20.97l-77.55 0c-14.71,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.19 67.11c-3.51,13.29 -9.77,20.97 -24.48,20.97l-70.58 0c-14.73,0 -19.62,-7.68 -15.41,-20.97l126.55 -433.48c4.21,-13.99 11.19,-20.89 25.86,-20.89l75.53 0c14.66,0 21.65,6.9 25.86,20.89z" />
                    <path fill="#FEFEFE" d="M867.2 4809.99c6.99,10.52 4.2,20.97 -10.52,20.97l-96.42 0c-14.73,0 -22.4,-10.45 -29.39,-20.97l-110.47 -171.28 -7.66 0 0 171.28c0,14.72 -4.97,20.97 -19.62,20.97l-75.45 0c-14.72,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.58,-20.91 20.3,-20.91l75.45 0c14.66,0 19.62,6.25 19.62,20.91l0 178.33 6.91 0 107.02 -178.33c6.99,-11.22 18.18,-20.91 32.84,-20.91l80.42 0c14.66,0 16.76,9.69 9.78,20.91l-132.88 212.58 150.37 220.88z" />
                    <path fill="#FEFEFE" d="M1032.2 4736.56l137 0c14.73,0 20.98,4.89 20.98,19.54l0 54.57c0,14.72 -6.25,20.28 -20.98,20.28l-234.85 0c-12.62,0 -17.49,-6.99 -17.49,-20.97l0 -433.47c0,-14.66 5.55,-20.91 20.27,-20.91l75.53 0c14.67,0 19.54,6.25 19.54,20.91l0 360.04z" />
                    <path fill="#FEFEFE" d="M1361.45 4809.99c0,14.72 -4.88,20.97 -19.54,20.97l-75.52 0c-14.73,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l75.52 0c14.67,0 19.54,6.25 19.54,20.91l0 433.47z" />
                    <path fill="#FEFEFE" d="M1890 4809.99c0,14.72 -4.89,20.97 -19.54,20.97l-72.08 0c-14.66,0 -20.22,-6.25 -20.22,-20.97l0 -221.64c-0.75,2.13 -14.04,51.11 -22.38,73.43l-30.81 76.2c-5.56,13.99 -13.24,20.97 -27.2,20.97l-60.81 0c-13.98,0 -21.05,-8.35 -26.6,-20.97l-38.41 -88.82c-7.73,-18.12 -16.83,-53.07 -18.26,-58.68l0 219.51c0,14.72 -4.8,20.97 -19.54,20.97l-68.45 0c-14.73,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l68.45 0c10.53,0 14.74,4.21 18.21,11.19l95.05 213.89c9.78,23.73 18.87,52.46 20.3,55.17 0,-1.36 6.99,-23.01 20.22,-53.74l91.62 -216.82c2.84,-6.9 8.4,-9.69 18.84,-9.69l72.08 0c14.66,0 19.54,6.25 19.54,20.91l0 433.47z" />
                    <path fill="#FEFEFE" d="M2149.39 4614.22c-11.2,-42.6 -20.97,-139.84 -21.65,-146.09 -1.43,9.84 -12.62,105.58 -23.81,146.09l-10.45 37.79 65.67 0 -9.77 -37.79zm44.04 -237.69l126.5 433.47c4.27,13.29 -0.67,20.97 -15.34,20.97l-77.57 0c-14.72,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.19 67.11c-3.53,13.29 -9.78,20.97 -24.48,20.97l-70.58 0c-14.73,0 -19.62,-7.68 -15.41,-20.97l126.55 -433.47c4.21,-13.98 11.2,-20.91 25.87,-20.91l75.52 0c14.66,0 21.63,6.93 25.85,20.91z" />
                    <path fill="#FEFEFE" d="M2610.1 4355.59c14.66,0 20.97,4.89 20.97,19.54l0 54.57c0,14.66 -6.32,20.22 -20.97,20.22l-89.5 0 0 360.04c0,14.74 -4.89,20.97 -19.54,20.97l-75.54 0c-14.66,0 -20.21,-6.23 -20.21,-20.97l0 -360.04 -89.51 0c-14.73,0 -20.96,-5.56 -20.96,-20.22l0 -54.57c0,-14.66 6.23,-19.54 20.96,-19.54l294.3 0z" />
                    <path fill="#FEFEFE" d="M2819.85 4515.69c5.64,16.08 11.19,39.14 11.87,41.93 0.75,-2.79 7.68,-27.96 13.98,-41.93l58.77 -139.19c4.88,-11.8 9.77,-20.88 24.42,-20.88l74.09 0c14.74,0 21.67,7.65 15.42,20.88l-128.65 278.29 0 155.2c0,14.72 -4.83,20.97 -19.54,20.97l-75.54 0c-14.66,0 -20.21,-6.25 -20.21,-20.97l0 -141.23 -129.41 -292.26c-5.56,-12.54 0,-20.88 14.73,-20.88l85.28 0c14.66,0 20.91,8.34 25.18,20.88l49.61 139.19z" />
                    <path fill="#FEFEFE" d="M3395.2 4716.99c0,60.2 -40.58,119.56 -172.02,119.56 -131.37,0 -171.94,-59.36 -171.94,-119.56l0 -247.47c0,-60.12 40.57,-119.56 171.94,-119.56 131.44,0 172.02,59.44 172.02,119.56l0 32.16c0,14.66 -4.88,20.97 -19.54,20.97l-72.05 0c-14.66,0 -20.28,-6.32 -20.28,-20.97l0 -11.88c0,-32.16 -24.43,-45.46 -58.71,-45.46 -34.26,0 -58.01,13.29 -58.01,45.46l0 206.97c0,32.16 23.75,45.47 58.01,45.47 34.28,0 58.71,-13.31 58.71,-45.47l0 -22.4c0,-14.66 5.63,-21.03 20.28,-21.03l72.05 0c14.67,0 19.54,6.38 19.54,21.03l0 42.62z" />
                    <path fill="#FEFEFE" d="M3721.02 4355.59c14.66,0 20.97,4.89 20.97,19.54l0 54.57c0,6.98 -2.1,11.19 -4.14,15.39l-162.25 291.45 157.97 0c14.72,0 20.97,4.97 20.97,19.62l0 54.5c0,14.71 -6.25,20.27 -20.97,20.27l-275.44 0c-14.66,0 -20.97,-5.56 -20.97,-20.27l0 -54.5c0,-6.32 1.43,-10.53 3.54,-13.98l162.85 -292.26 -133.53 0c-14.67,0 -20.97,-5.56 -20.97,-20.22l0 -54.57c0,-14.66 6.3,-19.54 20.97,-19.54l251 0z" />
                    <path fill="#FEFEFE" d="M4164.21 4809.99c0,14.72 -4.89,20.97 -19.54,20.97l-68.53 0c-10.45,0 -14.74,-2.8 -18.87,-9.78l-111.84 -185.92c-11.87,-18.93 -22.38,-43.37 -30.11,-62.99l0 237.72c0,14.72 -4.89,20.97 -19.54,20.97l-68.54 0c-14.66,0 -20.22,-6.25 -20.22,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.22,-20.91l68.54 0c9.09,0 14.66,2.79 17.44,8.34l118.2 197.8c8.35,14.73 17.44,35.7 24.51,52.46l0 -237.69c0,-14.66 5.47,-20.91 20.22,-20.91l68.53 0c14.66,0 19.54,6.25 19.54,20.91l0 433.47z" />
                    <path fill="#FEFEFE" d="M4423.66 4614.22c-11.2,-42.6 -20.97,-139.84 -21.66,-146.09 -1.43,9.84 -12.62,105.58 -23.82,146.09l-10.44 37.79 65.69 0 -9.77 -37.79zm44.03 -237.69l126.48 433.47c4.27,13.29 -0.67,20.97 -15.33,20.97l-77.58 0c-14.71,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.17 67.11c-3.54,13.29 -9.78,20.97 -24.51,20.97l-70.58 0c-14.71,0 -19.6,-7.68 -15.41,-20.97l126.57 -433.47c4.19,-13.98 11.19,-20.91 25.85,-20.91l75.52 0c14.66,0 21.65,6.93 25.86,20.91z" />
                    <g>
                        <path fill="#FEFEFE" d="M2843.96 290.34c-0.47,0 -1.02,0.05 -1.48,0.13l1.48 -0.13zm-1.41 64.38c0.47,0.08 1.02,0.15 1.49,0.22l-1.49 -0.22zm-458.12 72.47l-0.94 0.08c0.33,0 0.6,-0.08 0.94,-0.08zm1565.35 182.14l0.05 0 -0.05 0zm-2858.57 123.37l0 0.13 0 -0.13zm-10.78 20.42l-1.43 0.61c0.46,-0.2 0.94,-0.39 1.43,-0.61zm2789 1455.33c-0.08,0.14 -0.13,0.22 -0.13,0.28l0.13 -0.28zm-288.79 866.28c0,0.05 0,0.05 0,0.13l0 -0.13zm-2430.52 -725.93l1114.76 749.74 1320.78 -38.81 282.67 -848.22 58.44 -1602.73 -1394.68 -32.23c-13.37,-0.41 -26.05,-4.42 -36.98,-11.54l-153.36 -101.45c-25.31,-16.83 -37.04,-47.7 -29.23,-76.94 7.79,-29.31 33.31,-50.29 63.57,-52.25l178.05 -16.76 -404.7 -52.19 -1057.68 482.46 58.35 1600.91zm1108.24 843.11c-14.18,0 -27.68,-4.06 -39.36,-11.87l-1130.5 -760.35c-18.73,-12.54 -30.33,-33.45 -31.16,-55.97l-59.31 -1627.62c-1.07,-29.74 16.76,-57.07 44.39,-68.07l1092.58 -498.49 719.83 92.78c30.39,3.17 53.87,29.58 53.26,61.4 -0.61,31.83 -24.9,57.41 -56.72,59.51l-404.58 38.07 93.99 62.22 1411.16 32.63c18.86,0.41 36.57,8.23 49.59,22.07 13.1,13.9 19.96,32.04 19.21,51.02l-59.37 1628.99c-0.27,6.93 -1.5,13.64 -3.65,20.03l-288.68 865.98c-9.15,27.83 -35.22,47.25 -64.73,48.13l-1343.92 39.55 -2.04 0z" />
                    </g>
                    <path fill="#FEFEFE" d="M1085.55 1533.73c-7.26,0 -14.59,-0.05 -21.86,-0.13 -25.77,-0.33 -46.33,-21.45 -46.07,-47.17 0.33,-25.71 21.24,-47.43 47.17,-46.07 645.37,7.87 1290.1,-338.48 1640.23,-881.74 13.99,-21.63 42.69,-27.81 64.4,-13.9 21.65,13.98 27.9,42.82 13.99,64.45 -363.18,563.36 -1027.72,924.56 -1697.87,924.56z" />
                    <path fill="#FEFEFE" d="M1330.07 2573.09c-23.62,0 -43.91,-17.9 -46.36,-41.85 -2.58,-25.67 16.02,-48.46 41.66,-51.11 514.29,-52.51 1015.64,-264.31 1411.78,-596.54 396.07,-332.15 691.94,-788.97 833.22,-1286.29 7,-24.78 32.72,-39.36 57.55,-32.11 24.75,7 39.14,32.86 32.09,57.61 -146.28,515.04 -452.74,988.21 -862.93,1332.24 -410.27,344.05 -929.58,563.5 -1462.25,617.86 -1.58,0.13 -3.2,0.19 -4.75,0.19z" />
                    <path fill="#FEFEFE" d="M2262.4 3140.91c-17.57,0 -34.33,-9.98 -42.27,-26.87 -10.85,-23.35 -0.81,-51.11 22.52,-61.96 709.13,-330.68 1295.14,-896.8 1650.08,-1594.13 11.68,-22.92 39.79,-32.02 62.71,-20.4 22.93,11.73 32.11,39.75 20.44,62.68 -364.39,715.76 -965.95,1296.9 -1693.8,1636.32 -6.39,2.92 -13.1,4.34 -19.68,4.34z" />
                </g>
            </g>
        </svg>
        : <svg width="95px"
        viewBox="0 0 5093.01 5093.01">
        <g id="Layer_x0020_1">
            <rect fill="none" width="5093.01" height="5093.01" />
            <g id="_2986853185824">
                <path fill="#2B4388" d="M1669.11 3645.96c0,-32.16 -22.4,-44.03 -54.56,-44.03 -32.16,0 -55.25,11.87 -55.25,44.03l0 6.99c0,18.93 14.74,27.29 22.4,30.07l117.47 39.81c51.71,17.44 87.4,53.87 87.4,105.6l0 49.58c0,60.21 -42.68,119.57 -174.05,119.57 -131.51,0 -175.54,-59.36 -175.54,-119.57l0 -46.74c0,-14.73 5.64,-21.03 20.3,-21.03l72.68 0c14.71,0 19.6,6.3 19.6,21.03l0 31.36c0,32.21 27.27,44.11 61.55,44.11 34.2,0 62.22,-11.89 62.22,-44.11l0 -13.93c0,-25.16 -33.58,-37.05 -60.12,-46.88l-100.7 -37.74c-35.63,-13.29 -64.99,-52.37 -64.99,-92.96l0 -40.57c0,-60.14 36.29,-119.57 165.69,-119.57 129.27,0 168.5,59.44 168.5,119.57l0 25.91c0,14.66 -5.64,20.91 -20.3,20.91l-72.75 0c-14.66,0 -19.53,-6.25 -19.53,-20.91l0 -10.52z" />
                <path fill="#2B4388" d="M2128.39 3516.66c14.74,0 20.97,4.88 20.97,19.53l0 54.56c0,14.66 -6.23,20.22 -20.97,20.22l-89.5 0 0 360.06c0,14.71 -4.89,20.96 -19.54,20.96l-75.52 0c-14.66,0 -20.22,-6.25 -20.22,-20.96l0 -360.06 -89.52 0c-14.71,0 -20.96,-5.56 -20.96,-20.22l0 -54.56c0,-14.66 6.25,-19.53 20.96,-19.53l294.3 0z" />
                <path fill="#2B4388" d="M2312.29 3611.01l0 111.22 55.91 0c34.29,0 63.6,-13.29 63.6,-45.48l0 -20.27c0,-32.19 -29.31,-45.47 -63.6,-45.47l-55.91 0zm160.82 188.1l68.48 171.94c4.89,12.62 -4.15,20.97 -19.54,20.97l-75.53 0c-13.29,0 -16.08,-11.19 -20.3,-20.97l-61.49 -154.5 -52.45 0 0 154.5c0,14.66 -4.89,20.97 -19.54,20.97l-75.52 0c-14.73,0 -20.3,-6.32 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l150.97 0c131.44,0 177.58,59.38 177.58,119.51l0 60.8c0,39.9 -20.27,79.73 -72.66,102.13z" />
                <path fill="#2B4388" d="M2639.45 3992.02c-14.71,0 -20.21,-6.32 -20.21,-20.97l0 -433.47c0,-14.66 5.5,-20.91 20.21,-20.91l239.14 0c14.66,0 20.96,4.83 20.96,19.54l0 54.57c0,14.66 -6.3,20.22 -20.96,20.22l-144.06 0 0 90.92 132.85 0c14.67,0 20.97,4.88 20.97,19.54l0 51.03c0,14.73 -6.3,20.21 -20.97,20.21l-132.85 0 0 104.92 150.98 0c14.71,0 20.96,4.88 20.96,19.54l0 54.56c0,14.67 -6.25,20.28 -20.96,20.28l-246.07 0z" />
                <path fill="#2B4388" d="M3269.41 3536.22l0 54.55c0,14.66 -6.3,20.24 -20.96,20.24l-160.09 0 0 90.92 148.88 0c14.67,0 20.97,4.88 20.97,19.54l0 54.55c0,14.66 -6.3,20.3 -20.97,20.3l-148.88 0 0 174.73c0,14.66 -4.95,20.97 -19.61,20.97l-75.52 0c-14.67,0 -20.22,-6.32 -20.22,-20.97l0 -433.47c0,-14.66 5.55,-20.91 20.22,-20.91l255.21 0c14.66,0 20.96,4.81 20.96,19.54z" />
                <path fill="#2B4388" d="M3470.03 3775.27c-11.19,-42.6 -20.97,-139.85 -21.65,-146.09 -1.41,9.83 -12.62,105.58 -23.81,146.09l-10.44 37.8 65.66 0 -9.77 -37.8zm44.05 -237.7l126.48 433.48c4.27,13.29 -0.69,20.97 -15.36,20.97l-77.55 0c-14.71,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.19 67.11c-3.51,13.29 -9.77,20.97 -24.48,20.97l-70.58 0c-14.73,0 -19.62,-7.68 -15.41,-20.97l126.55 -433.48c4.21,-13.99 11.19,-20.89 25.86,-20.89l75.53 0c14.66,0 21.65,6.9 25.86,20.89z" />
                <path fill="#2B4388" d="M867.2 4809.99c6.99,10.52 4.2,20.97 -10.52,20.97l-96.42 0c-14.73,0 -22.4,-10.45 -29.39,-20.97l-110.47 -171.28 -7.66 0 0 171.28c0,14.72 -4.97,20.97 -19.62,20.97l-75.45 0c-14.72,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.58,-20.91 20.3,-20.91l75.45 0c14.66,0 19.62,6.25 19.62,20.91l0 178.33 6.91 0 107.02 -178.33c6.99,-11.22 18.18,-20.91 32.84,-20.91l80.42 0c14.66,0 16.76,9.69 9.78,20.91l-132.88 212.58 150.37 220.88z" />
                <path fill="#2B4388" d="M1032.2 4736.56l137 0c14.73,0 20.98,4.89 20.98,19.54l0 54.57c0,14.72 -6.25,20.28 -20.98,20.28l-234.85 0c-12.62,0 -17.49,-6.99 -17.49,-20.97l0 -433.47c0,-14.66 5.55,-20.91 20.27,-20.91l75.53 0c14.67,0 19.54,6.25 19.54,20.91l0 360.04z" />
                <path fill="#2B4388" d="M1361.45 4809.99c0,14.72 -4.88,20.97 -19.54,20.97l-75.52 0c-14.73,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l75.52 0c14.67,0 19.54,6.25 19.54,20.91l0 433.47z" />
                <path fill="#2B4388" d="M1890 4809.99c0,14.72 -4.89,20.97 -19.54,20.97l-72.08 0c-14.66,0 -20.22,-6.25 -20.22,-20.97l0 -221.64c-0.75,2.13 -14.04,51.11 -22.38,73.43l-30.81 76.2c-5.56,13.99 -13.24,20.97 -27.2,20.97l-60.81 0c-13.98,0 -21.05,-8.35 -26.6,-20.97l-38.41 -88.82c-7.73,-18.12 -16.83,-53.07 -18.26,-58.68l0 219.51c0,14.72 -4.8,20.97 -19.54,20.97l-68.45 0c-14.73,0 -20.3,-6.25 -20.3,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.3,-20.91l68.45 0c10.53,0 14.74,4.21 18.21,11.19l95.05 213.89c9.78,23.73 18.87,52.46 20.3,55.17 0,-1.36 6.99,-23.01 20.22,-53.74l91.62 -216.82c2.84,-6.9 8.4,-9.69 18.84,-9.69l72.08 0c14.66,0 19.54,6.25 19.54,20.91l0 433.47z" />
                <path fill="#2B4388" d="M2149.39 4614.22c-11.2,-42.6 -20.97,-139.84 -21.65,-146.09 -1.43,9.84 -12.62,105.58 -23.81,146.09l-10.45 37.79 65.67 0 -9.77 -37.79zm44.04 -237.69l126.5 433.47c4.27,13.29 -0.67,20.97 -15.34,20.97l-77.57 0c-14.72,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.19 67.11c-3.53,13.29 -9.78,20.97 -24.48,20.97l-70.58 0c-14.73,0 -19.62,-7.68 -15.41,-20.97l126.55 -433.47c4.21,-13.98 11.2,-20.91 25.87,-20.91l75.52 0c14.66,0 21.63,6.93 25.85,20.91z" />
                <path fill="#2B4388" d="M2610.1 4355.59c14.66,0 20.97,4.89 20.97,19.54l0 54.57c0,14.66 -6.32,20.22 -20.97,20.22l-89.5 0 0 360.04c0,14.74 -4.89,20.97 -19.54,20.97l-75.54 0c-14.66,0 -20.21,-6.23 -20.21,-20.97l0 -360.04 -89.51 0c-14.73,0 -20.96,-5.56 -20.96,-20.22l0 -54.57c0,-14.66 6.23,-19.54 20.96,-19.54l294.3 0z" />
                <path fill="#2B4388" d="M2819.85 4515.69c5.64,16.08 11.19,39.14 11.87,41.93 0.75,-2.79 7.68,-27.96 13.98,-41.93l58.77 -139.19c4.88,-11.8 9.77,-20.88 24.42,-20.88l74.09 0c14.74,0 21.67,7.65 15.42,20.88l-128.65 278.29 0 155.2c0,14.72 -4.83,20.97 -19.54,20.97l-75.54 0c-14.66,0 -20.21,-6.25 -20.21,-20.97l0 -141.23 -129.41 -292.26c-5.56,-12.54 0,-20.88 14.73,-20.88l85.28 0c14.66,0 20.91,8.34 25.18,20.88l49.61 139.19z" />
                <path fill="#2B4388" d="M3395.2 4716.99c0,60.2 -40.58,119.56 -172.02,119.56 -131.37,0 -171.94,-59.36 -171.94,-119.56l0 -247.47c0,-60.12 40.57,-119.56 171.94,-119.56 131.44,0 172.02,59.44 172.02,119.56l0 32.16c0,14.66 -4.88,20.97 -19.54,20.97l-72.05 0c-14.66,0 -20.28,-6.32 -20.28,-20.97l0 -11.88c0,-32.16 -24.43,-45.46 -58.71,-45.46 -34.26,0 -58.01,13.29 -58.01,45.46l0 206.97c0,32.16 23.75,45.47 58.01,45.47 34.28,0 58.71,-13.31 58.71,-45.47l0 -22.4c0,-14.66 5.63,-21.03 20.28,-21.03l72.05 0c14.67,0 19.54,6.38 19.54,21.03l0 42.62z" />
                <path fill="#2B4388" d="M3721.02 4355.59c14.66,0 20.97,4.89 20.97,19.54l0 54.57c0,6.98 -2.1,11.19 -4.14,15.39l-162.25 291.45 157.97 0c14.72,0 20.97,4.97 20.97,19.62l0 54.5c0,14.71 -6.25,20.27 -20.97,20.27l-275.44 0c-14.66,0 -20.97,-5.56 -20.97,-20.27l0 -54.5c0,-6.32 1.43,-10.53 3.54,-13.98l162.85 -292.26 -133.53 0c-14.67,0 -20.97,-5.56 -20.97,-20.22l0 -54.57c0,-14.66 6.3,-19.54 20.97,-19.54l251 0z" />
                <path fill="#2B4388" d="M4164.21 4809.99c0,14.72 -4.89,20.97 -19.54,20.97l-68.53 0c-10.45,0 -14.74,-2.8 -18.87,-9.78l-111.84 -185.92c-11.87,-18.93 -22.38,-43.37 -30.11,-62.99l0 237.72c0,14.72 -4.89,20.97 -19.54,20.97l-68.54 0c-14.66,0 -20.22,-6.25 -20.22,-20.97l0 -433.47c0,-14.66 5.56,-20.91 20.22,-20.91l68.54 0c9.09,0 14.66,2.79 17.44,8.34l118.2 197.8c8.35,14.73 17.44,35.7 24.51,52.46l0 -237.69c0,-14.66 5.47,-20.91 20.22,-20.91l68.53 0c14.66,0 19.54,6.25 19.54,20.91l0 433.47z" />
                <path fill="#2B4388" d="M4423.66 4614.22c-11.2,-42.6 -20.97,-139.84 -21.66,-146.09 -1.43,9.84 -12.62,105.58 -23.82,146.09l-10.44 37.79 65.69 0 -9.77 -37.79zm44.03 -237.69l126.48 433.47c4.27,13.29 -0.67,20.97 -15.33,20.97l-77.58 0c-14.71,0 -21.02,-7.68 -24.48,-20.97l-18.19 -67.11 -116.03 0 -18.17 67.11c-3.54,13.29 -9.78,20.97 -24.51,20.97l-70.58 0c-14.71,0 -19.6,-7.68 -15.41,-20.97l126.57 -433.47c4.19,-13.98 11.19,-20.91 25.85,-20.91l75.52 0c14.66,0 21.65,6.93 25.86,20.91z" />
                <g>
                    <path fill="#2B4388" d="M2843.96 290.34c-0.47,0 -1.02,0.05 -1.48,0.13l1.48 -0.13zm-1.41 64.38c0.47,0.08 1.02,0.15 1.49,0.22l-1.49 -0.22zm-458.12 72.47l-0.94 0.08c0.33,0 0.6,-0.08 0.94,-0.08zm1565.35 182.14l0.05 0 -0.05 0zm-2858.57 123.37l0 0.13 0 -0.13zm-10.78 20.42l-1.43 0.61c0.46,-0.2 0.94,-0.39 1.43,-0.61zm2789 1455.33c-0.08,0.14 -0.13,0.22 -0.13,0.28l0.13 -0.28zm-288.79 866.28c0,0.05 0,0.05 0,0.13l0 -0.13zm-2430.52 -725.93l1114.76 749.74 1320.78 -38.81 282.67 -848.22 58.44 -1602.73 -1394.68 -32.23c-13.37,-0.41 -26.05,-4.42 -36.98,-11.54l-153.36 -101.45c-25.31,-16.83 -37.04,-47.7 -29.23,-76.94 7.79,-29.31 33.31,-50.29 63.57,-52.25l178.05 -16.76 -404.7 -52.19 -1057.68 482.46 58.35 1600.91zm1108.24 843.11c-14.18,0 -27.68,-4.06 -39.36,-11.87l-1130.5 -760.35c-18.73,-12.54 -30.33,-33.45 -31.16,-55.97l-59.31 -1627.62c-1.07,-29.74 16.76,-57.07 44.39,-68.07l1092.58 -498.49 719.83 92.78c30.39,3.17 53.87,29.58 53.26,61.4 -0.61,31.83 -24.9,57.41 -56.72,59.51l-404.58 38.07 93.99 62.22 1411.16 32.63c18.86,0.41 36.57,8.23 49.59,22.07 13.1,13.9 19.96,32.04 19.21,51.02l-59.37 1628.99c-0.27,6.93 -1.5,13.64 -3.65,20.03l-288.68 865.98c-9.15,27.83 -35.22,47.25 -64.73,48.13l-1343.92 39.55 -2.04 0z" />
                </g>
                <path fill="#2B4388" d="M1085.55 1533.73c-7.26,0 -14.59,-0.05 -21.86,-0.13 -25.77,-0.33 -46.33,-21.45 -46.07,-47.17 0.33,-25.71 21.24,-47.43 47.17,-46.07 645.37,7.87 1290.1,-338.48 1640.23,-881.74 13.99,-21.63 42.69,-27.81 64.4,-13.9 21.65,13.98 27.9,42.82 13.99,64.45 -363.18,563.36 -1027.72,924.56 -1697.87,924.56z" />
                <path fill="#2B4388" d="M1330.07 2573.09c-23.62,0 -43.91,-17.9 -46.36,-41.85 -2.58,-25.67 16.02,-48.46 41.66,-51.11 514.29,-52.51 1015.64,-264.31 1411.78,-596.54 396.07,-332.15 691.94,-788.97 833.22,-1286.29 7,-24.78 32.72,-39.36 57.55,-32.11 24.75,7 39.14,32.86 32.09,57.61 -146.28,515.04 -452.74,988.21 -862.93,1332.24 -410.27,344.05 -929.58,563.5 -1462.25,617.86 -1.58,0.13 -3.2,0.19 -4.75,0.19z" />
                <path fill="#2B4388" d="M2262.4 3140.91c-17.57,0 -34.33,-9.98 -42.27,-26.87 -10.85,-23.35 -0.81,-51.11 22.52,-61.96 709.13,-330.68 1295.14,-896.8 1650.08,-1594.13 11.68,-22.92 39.79,-32.02 62.71,-20.4 22.93,11.73 32.11,39.75 20.44,62.68 -364.39,715.76 -965.95,1296.9 -1693.8,1636.32 -6.39,2.92 -13.1,4.34 -19.68,4.34z" />
            </g>
        </g>
    </svg> }
        </React.Fragment>
    )
}

export default Strefa