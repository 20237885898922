const errorMessage = (status) => {
    switch (status) {
        case 413:
            return "Takie konto już istnieje. Przejdź do logowania. Jeśli nie pamiętasz hasła zresetuj hasło."
        case 414:
            return "Nieprawidłowy numer telefonu."
        case 415:
            return "Nieprawidłowy adres e-mail."

        default:
            return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
    }
}

export default errorMessage