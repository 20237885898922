const fetchQuicklyContact = async (payload) => {
    let token = sessionStorage.getItem('APIToken')    
    // console.log(JSON.stringify(JSON.stringify(payload))) 
        const res = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/euros_demo/modify_contact`, {
          method: "POST",
          headers:{
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json',
            'accept': 'application/json'
          },
          body: JSON.stringify({contact_quickly: true})
        })
        return res
}

export default fetchQuicklyContact
