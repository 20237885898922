import React, { useState, useEffect } from 'react'
import { VictoryBar, VictoryLabel, VictoryStack, VictoryChart, VictoryAxis } from 'victory'
import { Grid, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

const ExpenditureChart = ({ symulacja, eprice }) => {
  const [symMapAll, setSymMapAll] = useState(null)
  const [symMapGrz, setSymMapGrz] = useState(null)
  let energiaCalkowita = Object.entries(symulacja.Energia_elektryczna_ukladu_calkowita).reduce((newObj, [key, val]) => {
      newObj[1] = newObj[1] + val
    return newObj;
  })
  let energiaGrzalka = Object.entries(symulacja.Energia_elektryczna_grzalki_calkowita).reduce((newObj, [key, val]) => {
      newObj[1] = newObj[1] + val
    return newObj;
  })
  let energiaPompa = Object.entries(symulacja.Energia_elektryczna_pompy_calkowita).reduce((newObj, [key, val]) => {
      newObj[1] = newObj[1] + val
    return newObj;
  })
  let energiaCO = Object.entries(symulacja.Energia_uzytkowa_CO).reduce((newObj, [key, val]) => {
      newObj[1] = newObj[1] + val
    return newObj;
  })
  let energiaCWU = Object.entries(symulacja.Energia_uzytkowa_CWU).reduce((newObj, [key, val]) => {
      newObj[1] = newObj[1] + val
    return newObj;
  })



  useEffect(() => {
    let mounted = true

    let newSymMapAll = Object.entries(symulacja.Energia_elektryczna_ukladu_calkowita).map((item, index) => {
      return (
        { x: index + 1, y: item[1] }
      )
    })
    let newSymMapGrz = Object.entries(symulacja.Energia_elektryczna_grzalki_calkowita).map((item, index) => {
      return (
        { x: index + 1, y: item[1] }
      )
    })
    setSymMapAll(newSymMapAll)
    setSymMapGrz(newSymMapGrz)

    return () => {
      mounted = false
    }
  }, [symulacja])


  return (
    <React.Fragment>
    <Grid container>
        <Grid item xs={12}><Typography variant="body1" className='expenditureCaption' style={{margin: '60px 55px 0'}}><CircleIcon style={{verticalAlign: "middle"}} /> zużycie energii elektrycznej w kWh</Typography></Grid>
        </Grid>
    <Grid container>
        <Grid item xs={12}><Typography variant="body1" className='expenditureCaption' style={{margin: '0px 55px -30px'}}><CircleIcon style={{verticalAlign: "middle", color: 'red'}} /> grzałka elektryczna</Typography></Grid>
        </Grid>

      <Grid container>
        <Grid item xs={12} md={6}>
          <VictoryChart
            domainPadding={{ x: 15 }}
          >
            <VictoryAxis crossAxis
              tickValues={["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"]}
              tickLabelComponent={<VictoryLabel dx={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
              label="Miesiąc"
              style={{
                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                axis: { stroke: "#ffffff" },
                data: { fill: '#ffffff', stroke: "#ffffff" }
              }}
            />
            <VictoryAxis dependentAxis
              tickLabelComponent={<VictoryLabel dy={0} style={{ fontSize: 12, fill: '#ffffff' }} />}
              label="Energia elektryczna kWh"
              style={{
                axisLabel: { padding: 35, fill: '#ffffff', fontSize: 14 },
                axis: { stroke: "#ffffff" },
                data: { fill: '#ffffff', stroke: "#ffffff" }
              }}
            />
            <VictoryStack>
            <VictoryBar
              cornerRadius={3}
              style={{
                data: {
                  fill: '#e31d21'
                }
              }}
              data={symMapGrz}
            // animate={{
            //   duration: 2000,
            //   // onLoad: { duration: 1000 }
            // }}
            />
            <VictoryBar
              cornerRadius={3}
              style={{
                data: {
                  fill: '#ffffff'
                }
              }}
              data={symMapAll}
              y0={(d)=>-1000}
            // animate={{
            //   duration: 2000,
            //   onLoad: { duration: 1000 }
            // }}
            />
            </VictoryStack>
          </VictoryChart>
        </Grid>
      <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ margin: "20px auto", textAlign: "center", textTransform: "uppercase", display: 'block', width: '100%' }}>Podsumowanie roczne</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}><Typography variant="body1">Całkowite zużycie energii</Typography></Grid>
            <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{energiaCalkowita[1].toFixed(2).replace(".", ",")} kWh</Typography></Grid>
          </Grid>
          <Grid container>
          <Grid item xs={8}><Typography variant="body1">Energia - pompa ciepła</Typography></Grid>
          <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{energiaPompa[1].toFixed(2).replace(".", ",")} kWh</Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}><Typography variant="body1">Energia - grzałka</Typography></Grid>
            <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{energiaGrzalka[1].toFixed(2).replace(".", ",")} kWh</Typography></Grid>
          </Grid>
          <Grid container>
          <Grid item xs={8}><Typography variant="body1">Wyprodukowane ciepło CO</Typography></Grid>
          <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{energiaCO[1].toFixed(2).replace(".", ",")} kWh</Typography></Grid>
          </Grid>
          <Grid container>
          <Grid item xs={8}><Typography variant="body1">Wyprodukowane ciepło CWU</Typography></Grid>
          <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{energiaCWU[1].toFixed(2).replace(".", ",")} kWh</Typography></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}><Typography variant="body1">Miesięczny koszt energii</Typography></Grid>
            <Grid item xs={4}><Typography style={{ textAlign: 'right' }} variant="body1">{((energiaCalkowita[1] * eprice) / 12).toFixed(2).replace(".", ",")} zł</Typography></Grid>
          </Grid>
          <Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ExpenditureChart