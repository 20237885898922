const errorMessage = (status) => {
    switch (status) {
        case 412:
            return "Błędne dane logowania. Sprawdź dane logowania lub przejdź do rejestracji."

        default:
            return "Wystąpił nieznany błąd. Skontaktuj się z administratorem."
    }
}

export default errorMessage