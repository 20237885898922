const fetchPompaSymulacja = async(payload) => {
    // console.log(JSON.stringify(payload))
    const res = await fetch(`${process.env.REACT_APP_FETCH_PUMP}rest/apps/pompa_i_symulacja`, {
      method: "POST",
      headers:{
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    return res
}

export default fetchPompaSymulacja