import React, { useState, useEffect } from 'react'
import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { mainStyleTheme } from './theme'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import { Login, Registration, Main } from './pages'

function App() {
  const [building, setBuilding] = useState(null)
  const [token, setToken] = useState(null)
  // let auth = []
  // if (sessionStorage.getItem("APIToken") && sessionStorage.getItem("APIToken") !== "undefined" && sessionStorage.getItem("APIToken") !== null) {
  //   auth.push(sessionStorage.getItem("APIToken"))
  //   auth.push(sessionStorage.getItem("building"))
  // }

  useEffect(() => {
    let mounted = true
    // const datenow = new Date()
    // let date = `${datenow.getFullYear()}-${datenow.getMonth() + 1 >= 10 ? datenow.getMonth() + 1 : `0${datenow.getMonth() + 1}`}-${datenow.getDate() >= 10 ? datenow.getDate() : `0${datenow.getDate()}`} ${datenow.getHours() >= 10 ? datenow.getHours() : `0${datenow.getHours()}`}:${datenow.getMinutes() >= 10 ? datenow.getMinutes() : `0${datenow.getMinutes()}`}:${datenow.getSeconds() >= 10 ? datenow.getSeconds() : `0${datenow.getSeconds()}`}`
    
    if (sessionStorage.getItem("APIToken") && sessionStorage.getItem("APIToken") !== "undefined" && sessionStorage.getItem("APIToken") !== null && token === null) {
      setToken(sessionStorage.getItem("APIToken"))
      }
    if (sessionStorage.getItem("building") && sessionStorage.getItem("building") !== "undefined" && sessionStorage.getItem("building") !== null){
      setBuilding(sessionStorage.getItem("building"))
    }
    // else{
    //   setBuilding(JSON.stringify([{
    //     Info: {
    //       Name: "budynek 1",
    //       Date: date
    //     },
    //     Data: {
    //       StrefaKlimatyczna: `3`,
    //       RokBudowy: 2015,
    //       Powierzchnia: 160,
    //       LiczbaDomownikow: 4,
    //       Rekuperacja: `1`,
    //       PowierzchniaNiemieszkalna: 60,
    //       PowierzchniaNieogrzewana: 60,
    //       KrzywaGrzewcza: 8,
    //       TemperaturaKomfortowaZima: 20,
    //       TemperaturaCwu: 45,
    //       CenaEnergii: 0.00
    //     }
    //   }]))}
    return () => {
      mounted = false
    }
  }, [])

  return (
    <Router>
      <ThemeProvider theme={mainStyleTheme}>
        <div className="App">
          <Routes>
            <Route exact path="/login" element={<Login setBuilding={setBuilding} setToken={setToken} />} />
            <Route exact path="/registration" element={<Registration />} />
            <Route exact path="/app" element={token ? <Main auth={building ? JSON.parse(building)[0] : null} /> : <Login setBuilding={setBuilding} setToken={setToken} />} />
            <Route exact path="/" element={<Login setBuilding={setBuilding} setToken={setToken} />} />
            <Route exact path="*" element={<Login setBuilding={setBuilding} setToken={setToken} />} />
          </Routes>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
