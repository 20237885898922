import React, { useState, useEffect } from 'react'
import { Grid, Tab, Tabs, Box } from '@mui/material'
import { Building, ClimateZone, Expenditure, Comfort, Logout } from './components'
import { Strefa, Budynek, Komfort, Zuzycie, Wyloguj } from '../../img'
import { fetchModify } from '../../data'

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Main = ({ auth }) => {
    const [value, setValue] = useState(0)
    const [settings, setSettings] = useState({
        climatezone: 3, rok_budowy: 2015, powierzchnia_mieszkalna: 160, liczba_domownikow: 4, rekuperacja: 1,
        powierzchnia_niemieszkalna: 60, powierzchnia_nieogrzewana: 60, krzywa_grzewcza: 8, temperatura_komfortowa_zima: 20, temperatura_cwu: 45, cena_energii: 0.00
    })

    const handleChange = (event, newValue) => {
        if (newValue === 3)
            sendBuilding()
        setValue(newValue)
    }

    const handleSettings = (name, val) => {
        setSettings({ ...settings, [name]: val })
    }

    const sendBuilding = () => {
        const datenow = new Date()
        let date = `${datenow.getFullYear()}-${datenow.getMonth() + 1 >= 10? datenow.getMonth() + 1 : `0${datenow.getMonth() + 1}` }-${datenow.getDate() >= 10? datenow.getDate() : `0${datenow.getDate()}`} ${datenow.getHours() >= 10? datenow.getHours() : `0${datenow.getHours()}`}:${datenow.getMinutes() >= 10? datenow.getMinutes() : `0${datenow.getMinutes()}`}:${datenow.getSeconds() >= 10? datenow.getSeconds() : `0${datenow.getSeconds()}`}`
        
        fetchModify([
                {
                    Info: {
                        Name: "budynek 1",
                        Date: date
                    },
                    Data: {
                        StrefaKlimatyczna: `${settings.climatezone}`,
                        RokBudowy: settings.rok_budowy,
                        Powierzchnia: settings.powierzchnia_mieszkalna,
                        LiczbaDomownikow: settings.liczba_domownikow,
                        Rekuperacja: `${settings.rekuperacja}`,
                        PowierzchniaNiemieszkalna: settings.powierzchnia_niemieszkalna,
                        PowierzchniaNieogrzewana: settings.powierzchnia_nieogrzewana,
                        KrzywaGrzewcza: settings.krzywa_grzewcza,
                        TemperaturaKomfortowaZima: settings.temperatura_komfortowa_zima,
                        TemperaturaCwu: settings.temperatura_cwu,
                        CenaEnergii: settings.cena_energii
                    }
                }]).then(res => console.log(res))
        }

    useEffect(() => {
    let mounted = true
    if (auth) {
        setSettings({
            climatezone: auth.Data.StrefaKlimatyczna ? parseInt(auth.Data.StrefaKlimatyczna) : 1,
            rok_budowy: auth.Data.RokBudowy ? auth.Data.RokBudowy : 2015,
            powierzchnia_mieszkalna: auth.Data.Powierzchnia ? auth.Data.Powierzchnia : 110,
            liczba_domownikow: auth.Data.LiczbaDomownikow ? auth.Data.LiczbaDomownikow : 4,
            rekuperacja: auth.Data.Rekuperacja ? parseInt(auth.Data.Rekuperacja) : 0,
            powierzchnia_niemieszkalna: auth.Data.PowierzchniaNiemieszkalna ? auth.Data.PowierzchniaNiemieszkalna : 60,
            powierzchnia_nieogrzewana: auth.Data.PowierzchniaNieogrzewana ? auth.Data.PowierzchniaNieogrzewana : 60,
            krzywa_grzewcza: auth.Data.KrzywaGrzewcza ? auth.Data.KrzywaGrzewcza : 3,
            temperatura_komfortowa_zima: auth.Data.TemperaturaKomfortowaZima ? auth.Data.TemperaturaKomfortowaZima : 20,
            temperatura_cwu: auth.Data.TemperaturaCwu ? auth.Data.TemperaturaCwu : 45,
            cena_energii: auth.Data.CenaEnergii ? auth.Data.CenaEnergii : 0.00,
        })
    }

    return () => {
        mounted = false
    }
}, [auth])

return (
    <React.Fragment>
        <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
                <Tabs value={value} onChange={handleChange} >
                    <Tab style={{ width: "20%" }} icon={<Strefa info={value === 0 ? true : false} />} {...a11yProps(0)} />
                    <Tab style={{ width: "20%" }} icon={<Budynek info={value === 1 ? true : false} />} {...a11yProps(1)} />
                    <Tab style={{ width: "20%" }} icon={<Komfort info={value === 2 ? true : false} />} {...a11yProps(2)} />
                    <Tab style={{ width: "20%" }} icon={<Zuzycie info={value === 3 ? true : false} />} {...a11yProps(3)} />
                    <Tab style={{ width: "20%" }} icon={<Wyloguj />} {...a11yProps(4)} />
                </Tabs>
            </Grid>
        </Grid>
        <Grid container style={{ marginTop: "30px" }}>
            <Grid item xs={12}>
                <TabPanel value={value} index={0}>
                    <ClimateZone handleChange={handleChange} zone={settings.climatezone} handleSettings={handleSettings} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Building handleChange={handleChange} building={settings} handleSettings={handleSettings} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Comfort handleChange={handleChange} comfort={settings} handleSettings={handleSettings} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Expenditure expenditure={settings} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Logout />
                </TabPanel>
            </Grid>
        </Grid>
    </React.Fragment>
)
}

export default Main