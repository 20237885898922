const dicMapaTemperaturProgowych = {
    1: 20,
    2: 20,
    3: 20,
    4: 18,
    5: 15,
    6: -100,
    7: -100,
    8: -100,
    9: 15,
    10: 18,
    11: 20,
    12: 20
}

export default dicMapaTemperaturProgowych