import React from "react"

const Zuzycie = ({ info }) => {
    return (
        <React.Fragment>
            {info ?
                <svg width="95px"
                    viewBox="0 0 272.75 272.75">
                    <g id="Layer_x0020_1">
                        <g id="_2986642816688">
                            <path fill="#FEFEFE" d="M86.62 190.11c0.78,0 1.12,0.26 1.12,1.05l0 2.92c0,0.38 -0.11,0.6 -0.22,0.83l-8.69 15.61 8.46 0c0.79,0 1.12,0.26 1.12,1.05l0 2.92c0,0.79 -0.33,1.09 -1.12,1.09l-14.75 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.33 0.08,-0.56 0.19,-0.75l8.72 -15.65 -7.15 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.78 0.34,-1.05 1.12,-1.05l13.44 0z" />
                            <path fill="#FEFEFE" d="M97.22 208.38c0,1.72 1.54,2.43 3.37,2.43 1.84,0 3.41,-0.71 3.41,-2.43l0 -17.15c0,-0.78 0.3,-1.12 1.09,-1.12l4.04 0c0.79,0 1.05,0.34 1.05,1.12l0 18.24c0,3.22 -2.54,6.4 -9.58,6.4 -7.04,0 -9.55,-3.18 -9.55,-6.4l0 -18.24c0,-0.78 0.3,-1.12 1.09,-1.12l4.04 0c0.79,0 1.05,0.34 1.05,1.12l0 17.15z" />
                            <path fill="#FEFEFE" d="M124.51 187.26c0,0.79 -0.26,1.09 -1.05,1.09l-3.97 0c-0.79,0 -1.09,-0.3 -1.09,-1.09l0 -3.11c0,-0.79 0.3,-1.12 1.09,-1.12l3.97 0c0.78,0 1.05,0.34 1.05,1.12l0 3.11zm3.86 2.85c0.78,0 1.12,0.26 1.12,1.05l0 2.92c0,0.37 -0.07,0.64 -0.22,0.83l-8.69 15.61 8.46 0c0.79,0 1.13,0.26 1.13,1.05l0 2.92c0,0.78 -0.34,1.09 -1.13,1.09l-14.75 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.33 0.04,-0.6 0.19,-0.75l8.72 -15.65 -7.15 0c-0.79,0 -1.13,-0.3 -1.13,-1.09l0 -2.92c0,-0.78 0.34,-1.05 1.13,-1.05l13.44 0z" />
                            <path fill="#FEFEFE" d="M140.53 198.69c0.3,0.86 0.6,2.1 0.64,2.25 0.04,-0.15 0.41,-1.5 0.75,-2.25l3.15 -7.45c0.26,-0.64 0.52,-1.12 1.31,-1.12l3.97 0c0.79,0 1.16,0.41 0.83,1.12l-6.89 14.9 0 8.31c0,0.79 -0.26,1.12 -1.05,1.12l-4.04 0c-0.78,0 -1.08,-0.33 -1.08,-1.12l0 -7.56 -6.93 -15.65c-0.3,-0.67 0,-1.12 0.79,-1.12l4.57 0c0.78,0 1.12,0.45 1.35,1.12l2.66 7.45z" />
                            <path fill="#FEFEFE" d="M171.35 209.47c0,3.22 -2.17,6.4 -9.21,6.4 -7.04,0 -9.21,-3.18 -9.21,-6.4l0 -13.25c0,-3.22 2.17,-6.4 9.21,-6.4 7.04,0 9.21,3.18 9.21,6.4l0 1.72c0,0.79 -0.26,1.12 -1.05,1.12l-3.86 0c-0.78,0 -1.09,-0.34 -1.09,-1.12l0 -0.64c0,-1.72 -1.31,-2.44 -3.14,-2.44 -1.84,0 -3.11,0.71 -3.11,2.44l0 11.08c0,1.72 1.27,2.44 3.11,2.44 1.83,0 3.14,-0.71 3.14,-2.44l0 -1.2c0,-0.78 0.3,-1.12 1.09,-1.12l3.86 0c0.78,0 1.05,0.34 1.05,1.12l0 2.29z" />
                            <path fill="#FEFEFE" d="M181.08 214.45c0,0.79 -0.26,1.12 -1.05,1.12l-4.05 0c-0.79,0 -1.09,-0.33 -1.09,-1.12l0 -23.21c0,-0.78 0.3,-1.12 1.09,-1.12l4.05 0c0.78,0 1.05,0.34 1.05,1.12l0 23.21z" />
                            <path fill="#FEFEFE" d="M186.66 215.57c-0.79,0 -1.08,-0.34 -1.08,-1.12l0 -23.22c0,-0.78 0.29,-1.12 1.08,-1.12l12.8 0c0.79,0 1.13,0.26 1.13,1.05l0 2.92c0,0.79 -0.34,1.09 -1.13,1.09l-7.71 0 0 4.87 7.11 0c0.79,0 1.13,0.26 1.13,1.05l0 2.73c0,0.78 -0.34,1.08 -1.13,1.08l-7.11 0 0 5.62 8.09 0c0.79,0 1.12,0.26 1.12,1.05l0 2.92c0,0.79 -0.33,1.09 -1.12,1.09l-13.18 0z" />
                            <path fill="#FEFEFE" d="M218.23 172.89l-166.2 0 0 -160.81c0,-1.38 1.12,-2.5 2.5,-2.5 1.38,0 2.5,1.12 2.5,2.5l0 155.82 161.21 0c1.38,0 2.5,1.12 2.5,2.5 0,1.38 -1.12,2.5 -2.5,2.5z" />
                            <path fill="#FEFEFE" d="M79.44 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -51.01c0,-2.06 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.68 3.74,3.74l0 51.01c0,2.07 -1.67,3.74 -3.74,3.74z" />
                            <path fill="#FEFEFE" d="M108.35 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -80.96c0,-2.07 1.68,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 80.96c0,2.07 -1.68,3.74 -3.74,3.74z" />
                            <path fill="#FEFEFE" d="M166.18 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -79.48c0,-2.07 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 79.48c0,2.07 -1.68,3.74 -3.74,3.74z" />
                            <path fill="#FEFEFE" d="M195.1 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -96.09c0,-2.07 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 96.09c0,2.07 -1.67,3.74 -3.74,3.74z" />
                            <path fill="#FEFEFE" d="M137.27 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -95.5c0,-2.07 1.68,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 95.5c0,2.07 -1.68,3.74 -3.74,3.74z" />
                        </g>
                        <rect fill="none" width="272.75" height="272.75" />
                    </g>
                </svg>
                : <svg width="95px"
                    viewBox="0 0 272.75 272.75">
                    <g id="Layer_x0020_1">
                        <g id="_2986722354160">
                            <path fill="#2B4388" d="M86.62 190.11c0.78,0 1.12,0.26 1.12,1.05l0 2.92c0,0.38 -0.11,0.6 -0.22,0.83l-8.69 15.61 8.46 0c0.79,0 1.12,0.26 1.12,1.05l0 2.92c0,0.79 -0.33,1.09 -1.12,1.09l-14.75 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.33 0.08,-0.56 0.19,-0.75l8.72 -15.65 -7.15 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.78 0.34,-1.05 1.12,-1.05l13.44 0z" />
                            <path fill="#2B4388" d="M97.22 208.38c0,1.72 1.54,2.43 3.37,2.43 1.84,0 3.41,-0.71 3.41,-2.43l0 -17.15c0,-0.78 0.3,-1.12 1.09,-1.12l4.04 0c0.79,0 1.05,0.34 1.05,1.12l0 18.24c0,3.22 -2.54,6.4 -9.58,6.4 -7.04,0 -9.55,-3.18 -9.55,-6.4l0 -18.24c0,-0.78 0.3,-1.12 1.09,-1.12l4.04 0c0.79,0 1.05,0.34 1.05,1.12l0 17.15z" />
                            <path fill="#2B4388" d="M124.51 187.26c0,0.79 -0.26,1.09 -1.05,1.09l-3.97 0c-0.79,0 -1.09,-0.3 -1.09,-1.09l0 -3.11c0,-0.79 0.3,-1.12 1.09,-1.12l3.97 0c0.78,0 1.05,0.34 1.05,1.12l0 3.11zm3.86 2.85c0.78,0 1.12,0.26 1.12,1.05l0 2.92c0,0.37 -0.07,0.64 -0.22,0.83l-8.69 15.61 8.46 0c0.79,0 1.13,0.26 1.13,1.05l0 2.92c0,0.78 -0.34,1.09 -1.13,1.09l-14.75 0c-0.78,0 -1.12,-0.3 -1.12,-1.09l0 -2.92c0,-0.33 0.04,-0.6 0.19,-0.75l8.72 -15.65 -7.15 0c-0.79,0 -1.13,-0.3 -1.13,-1.09l0 -2.92c0,-0.78 0.34,-1.05 1.13,-1.05l13.44 0z" />
                            <path fill="#2B4388" d="M140.53 198.69c0.3,0.86 0.6,2.1 0.64,2.25 0.04,-0.15 0.41,-1.5 0.75,-2.25l3.15 -7.45c0.26,-0.64 0.52,-1.12 1.31,-1.12l3.97 0c0.79,0 1.16,0.41 0.83,1.12l-6.89 14.9 0 8.31c0,0.79 -0.26,1.12 -1.05,1.12l-4.04 0c-0.78,0 -1.08,-0.33 -1.08,-1.12l0 -7.56 -6.93 -15.65c-0.3,-0.67 0,-1.12 0.79,-1.12l4.57 0c0.78,0 1.12,0.45 1.35,1.12l2.66 7.45z" />
                            <path fill="#2B4388" d="M171.35 209.47c0,3.22 -2.17,6.4 -9.21,6.4 -7.04,0 -9.21,-3.18 -9.21,-6.4l0 -13.25c0,-3.22 2.17,-6.4 9.21,-6.4 7.04,0 9.21,3.18 9.21,6.4l0 1.72c0,0.79 -0.26,1.12 -1.05,1.12l-3.86 0c-0.78,0 -1.09,-0.34 -1.09,-1.12l0 -0.64c0,-1.72 -1.31,-2.44 -3.14,-2.44 -1.84,0 -3.11,0.71 -3.11,2.44l0 11.08c0,1.72 1.27,2.44 3.11,2.44 1.83,0 3.14,-0.71 3.14,-2.44l0 -1.2c0,-0.78 0.3,-1.12 1.09,-1.12l3.86 0c0.78,0 1.05,0.34 1.05,1.12l0 2.29z" />
                            <path fill="#2B4388" d="M181.08 214.45c0,0.79 -0.26,1.12 -1.05,1.12l-4.05 0c-0.79,0 -1.09,-0.33 -1.09,-1.12l0 -23.21c0,-0.78 0.3,-1.12 1.09,-1.12l4.05 0c0.78,0 1.05,0.34 1.05,1.12l0 23.21z" />
                            <path fill="#2B4388" d="M186.66 215.57c-0.79,0 -1.08,-0.34 -1.08,-1.12l0 -23.22c0,-0.78 0.29,-1.12 1.08,-1.12l12.8 0c0.79,0 1.13,0.26 1.13,1.05l0 2.92c0,0.79 -0.34,1.09 -1.13,1.09l-7.71 0 0 4.87 7.11 0c0.79,0 1.13,0.26 1.13,1.05l0 2.73c0,0.78 -0.34,1.08 -1.13,1.08l-7.11 0 0 5.62 8.09 0c0.79,0 1.12,0.26 1.12,1.05l0 2.92c0,0.79 -0.33,1.09 -1.12,1.09l-13.18 0z" />
                            <path fill="#2B4388" d="M218.23 172.89l-166.2 0 0 -160.81c0,-1.38 1.12,-2.5 2.5,-2.5 1.38,0 2.5,1.12 2.5,2.5l0 155.82 161.21 0c1.38,0 2.5,1.12 2.5,2.5 0,1.38 -1.12,2.5 -2.5,2.5z" />
                            <path fill="#2B4388" d="M79.44 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -51.01c0,-2.06 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.68 3.74,3.74l0 51.01c0,2.07 -1.67,3.74 -3.74,3.74z" />
                            <path fill="#2B4388" d="M108.35 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -80.96c0,-2.07 1.68,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 80.96c0,2.07 -1.68,3.74 -3.74,3.74z" />
                            <path fill="#2B4388" d="M166.18 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -79.48c0,-2.07 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 79.48c0,2.07 -1.68,3.74 -3.74,3.74z" />
                            <path fill="#2B4388" d="M195.1 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -96.09c0,-2.07 1.67,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 96.09c0,2.07 -1.67,3.74 -3.74,3.74z" />
                            <path fill="#2B4388" d="M137.27 152.91c-2.07,0 -3.74,-1.68 -3.74,-3.74l0 -95.5c0,-2.07 1.68,-3.74 3.74,-3.74 2.07,0 3.74,1.67 3.74,3.74l0 95.5c0,2.07 -1.68,3.74 -3.74,3.74z" />
                        </g>
                        <rect fill="none" width="272.75" height="272.75" />
                    </g>
                </svg>}
        </React.Fragment>
    )
}

export default Zuzycie