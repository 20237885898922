const fetchLogin = async (login, password, setBuilding, setToken) => {

    const response = await fetch(`${process.env.REACT_APP_FETCH_DEV}rest/euros_demo/login`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login, password })
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            throw new Error(response.status)
        }
    }).then(res => {
        setBuilding(res.data.content)
        setToken(res.data.access_token)
        sessionStorage.setItem('APIToken', res.data.access_token)
        sessionStorage.setItem('building', res.data.content)
        return res
    }).then(res => res)

    return response
}

export default fetchLogin